.o-footer {
    background: #00427a;

    &_wrapper {
        padding: rem(28) 0;

        @include mobile-down {
            padding: rem(18) 0;
        }
    }

    &_contact {
        display: flex;
        align-items: center;
        @include mobile-down {
            justify-content: center;

            .a-text {
                margin-top: rem(4);
                @include font-base(16, 24); 
            }
        }
    }

    &_bottom {
        @include mobile-up {
            @include adjust-flex(space-between, center);
        }

        p {
            margin-left: rem(18);

            @include tablet-down {
                margin-left: rem(9);
            }
        }

    }

    &_icon {
        flex-shrink: 0;

        @include mobile-down {
            i {
                background-size: rem(16);
            }
        }
    }

    &_code {
        .a-text {
            white-space: nowrap;
            text-align: end;
            @include mobile-down {
                text-align: center;
            }
        }

        @include mobile-up {
            margin-left: rem(20);
        }

    }
}
