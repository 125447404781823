.a-button {
    $root: &;
    align-items: center;
    background: var(--dkngaystroke, $mellow-apricot);
    border: 0;
    border-radius: rem(50);
    color: $dark-cerulean;
    cursor: pointer;
    display: flex;
    font: inherit;
    font-weight: 700;
    height: rem(55);
    justify-content: center;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: rem(2);
    position: relative;
    text-transform: uppercase;
    transition: $transition-long ease;
    width: 100%;
    @include font-base(20, 32);

    @include tablet-down {
        @include font-base(16, 20);
        height: rem(49);
    }

    &:active {
        @include desktop-up {
            @include font-base(19, 32);
        }
    }

    &-h44 {
        height: rem(44);
        @include font-base(20, 32);

        @include mobile-down {
            height: rem(36);
            @include font-base(16, 24);
        }

        span {
            transform: translateY(3px) !important;
        }
    }

    &-h46 {
        height: rem(46);
        @include font-base(20, 32);

        @include mobile-down {
            height: rem(36);
            @include font-base(16, 24);
        }
    }

    &-h58 {
        height: rem(58);
        @include font-base(24, 32);

        @include tablet-down {
            height: rem(45);
            @include font-base(18, 28);
        }

        @include mobile-down {
            height: rem(36);
            @include font-base(16, 24);
        }
    }

    &-h56 {
        height: rem(56);
        @include font-base(24, 32);

        @include tablet-down {
            height: rem(48);
        }
    }

    &-border {
        #{$root}_wrapper {
            border: 1px solid $black;
        }
    }

    &-resize {
        @include tablet-down {
            font-size: rem(16);
            height: rem(40);
            line-height: 150%;

            &:active {
                font-size: rem(15);
            }
        }
    }

    &-secondary {
        background: $linear-seal-brown4;

        #{$root}_wrapper {
            background: none;
        }
    }

    &-loading {
        pointer-events: none;

        &::before {
            background: $turquoise;
        }
    }

    &-disabled {
        color: rgba($dark-cerulean, 0.7);
        filter: grayscale(1);
        pointer-events: none;
    }

    &_loadingIcon {
        position: relative;
        width: rem(30);
        z-index: $z-2;
    }

    &_wrapper {
        background: $linear-orange-title;
        border-radius: rem(66);
        height: 100%;
        width: 100%;
        @include adjust-flex(center, center);
    }

    span {
        position: relative;
        z-index: $z-2;
        transform: translateY(5px);
    }

    &-primary-2025 {
        background: url('~assets/images/new2025/button.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-height: rem(90);
        padding: rem(4) rem(32) rem(12);
        width: fit-content;
        min-width: rem(220);

        @include max-large-desktop-down {
            padding-bottom: rem(10);
            min-height: rem(80);
            min-width: rem(200);
        }

        @include max-desktop-down {
            min-height: rem(70);
            min-width: rem(190);
        }

        @include desktop-down {
            min-height: rem(60);
            min-width: rem(160);
        }

        @include medium-desktop-down {
            min-height: rem(64);
        }

        @include tablet-down {
            min-height: rem(60);
        }

        #{$root}_wrapper {
            background: none;
            height: 90%;

            #{$root}_loadingIcon {
                margin-top: rem(-12);
            }

            span {
                transform: translateY(0);
                @include font-base(26, 38);

                @include max-large-desktop-down {
                    @include font-base(24, 32);
                }

                @include max-desktop-down() {
                    @include font-base(21, 28);
                }

                @include desktop-down() {
                    @include font-base(18, 26);
                }

                @include tablet-down {
                    @include font-base(18, 26);
                }
            }
        }
    }
}
