$size-icon: (
    '12x12': (w: 12, h: 12),
    '16x16': (w: 16, h: 16),
    '18x18': (w: 18, h: 18),
    '20x20': (w: 20, h: 20),
    '24x24': (w: 24, h: 24),
    '26x26': (w: 26, h: 26),
    '26x15': (w: 26, h: 15),
    '28x28': (w: 28, h: 28),
    '32x32': (w: 32, h: 32),
    '36x36': (w: 36, h: 36),
    '40x40': (w: 40, h: 40),
    '48x48': (w: 48, h: 48),
    '60x60': (w: 60, h: 60),
    '34x40': (w: 34, h: 40),
);

$icon-src: (
    'muteBlue': '#{$icon-path}ic_mute_blue.png',
    'soundBlue': '#{$icon-path}ic_unmute_blue.png',
    'mute': '#{$icon-path}ic_mute.svg',
    'sound': '#{$icon-path}ic_volume.svg',
    'minimize': '#{$icon-path}ic_minimize.svg',
    'fullscreen': '#{$icon-path}ic_fullscreen.svg',
    'redo': '#{$icon-path}ic_redo.svg',
    'eyeBlue': '#{$icon-path}ic_eyes_open_blue.svg',
    'play': '#{$icon-path}ic_play.svg',
    'supporter': '#{$icon-path}ic_supporter.svg',
);

.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    @each $size,
        $val in $size-icon {
        &-#{$size} {
            height: map-get($val, 'h')+ px;
            width: map-get($val, 'w') + px;
        }
    }

    @each $icon,
        $val in $icon-src {
        &-#{$icon} {
            background-image: url($val);
        }
    }
}

