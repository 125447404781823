.p-survey {
    align-items: center;
    background-image: url('~assets/images/bg-survey.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: rem(40);
    padding-top: rem(40);
    @include tablet-down {
        padding-top: rem(24);
    }

    &-start {
        background: linear-gradient(90deg,
        #b2dad8 0%,
        #e5f5f0 15.47%,
        #e5f5f0 70.99%,
        #aad7d4 99.99%);
    }
}
