.o-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: rem(48);
    padding-right: rem(48);

    @include mobile-down {
        padding-left: rem(16);
        padding-right: rem(16);
    }

    &-noPaddingRight {
        padding-right: 0;
    }

    &-noPaddingLeft {
        padding-left: 0;
    }

    &-fullScreen {
        padding-left: 0;
        padding-right: 0;
    }
}
