$width-left-side: 68vw;

@mixin width-left-side {
    width: 68vw;

    @include desktop-down {
        width: 72vw;
    }
}

.t-livestream {
    $root: &;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;

    &.fullscreen {
        display: block;

        #{$root}_video {
            height: 100vh;
            width: 100vw;
        }

        #{$root}_comments {
            transform: translateX(100%);
        }
    }

    &_loading {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &_video {
        height: calc(100% - 253px);
        overflow: hidden;
        position: relative;
        transition: $transition-long ease;
        z-index: 2;
        @include width-left-side;

        @include tablet-down {
            height: calc(100% - 145px);
        }

        .video-js {
            height: 100% !important;
            padding-top: 0 !important;
            width: 100%;
        }
    }

    &_blur {
        background: linear-gradient(360deg, rgba($black, 0.4) 0%, rgba($black, 0) 100%);
        bottom: 0;
        height: 60px;
        position: absolute;
        width: 100%;
    }

    &_comments {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1.25rem 0.875rem;
        position: absolute;
        right: 0;
        top: 0;
        transition: $transition-long ease;
        width: 32vw;
        z-index: 10;
        @include scroll-bars(8, $spanish-gray);

        @include desktop-down {
            padding: rem(12);
            width: 32vw;
        }

        @include desktop-down {
            width: 28vw;
        }

        &_head {
            @include break-min(570px) {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-left: -4px;
                margin-right: -4px;
            }

            @include tablet-down {
                display: block;
            }

            .name {
                display: flex;
                flex: 1 0 0;
                padding-left: 4px;
                padding-right: 4px;

                .a-icon {
                    flex: 0 0 rem(24);
                    margin-right: 7px;
                    margin-top: 3px;
                    max-width: rem(24);

                    @include tablet-down {
                        flex: 0 0 rem(16);
                        margin-right: 3px;
                    }
                }

                .a-text {
                    @include desktop-down {
                        @include font-base(14, 20);
                    }

                    @include mobile-down {
                        @include font-base(12, 17);
                    }
                }
            }

            .time {
                opacity: 0.55;
                padding-left: 4px;
                padding-right: 4px;

                @include tablet-down {
                    margin-left: rem(20);
                    text-align: right;
                }

                .a-text {
                    @include desktop-down {
                        @include font-base(11, 17);
                    }
                }
            }
        }

        &_icon {
            align-items: center;
            display: flex;
            margin-right: rem(7);
        }

        &_content {
            .a-text {
                @include desktop-down {
                    @include font-base(12, 18);
                }
            }
        }
    }

    &_fullscreen {
        align-items: center;
        bottom: rem(12);
        cursor: pointer;
        display: flex;
        height: rem(36);
        justify-content: center;
        position: absolute;
        right: rem(24);
        width: rem(36);
        z-index: 5;
        background: rgba($black, 0.5);
        border-radius: rem(8);
    }

    &_sound {
        align-items: center;
        bottom: rem(12);
        cursor: pointer;
        display: flex;
        height: rem(36);
        justify-content: center;
        left: rem(100);
        position: absolute;
        width: rem(36);
        z-index: 5;
        background: rgba($black, 0.5);
        border-radius: rem(8);

        @include desktop-down {
            left: rem(16);
        }

        @include mobile-down {
            left: rem(12);
        }

        .m-volumeControl {
            min-width: unset;

            &-muted {
                i.icon {
                    height: 30px !important;
                    width: 30px !important;
                }
            }

            i.icon {
                height: 24px;
                width: 24px;
            }
        }
    }

    &_playButton {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 5;
    }

    &_action {
        flex: 0 0 60%;
        max-width: 60%;
        padding-left: rem(10);
        padding-right: rem(10);

        @include tablet-down {
            padding-left: 4px;
            padding-right: 4px;
        }

        &_button {
            @include adjust-flex(flex-start, center);
            max-width: rem(220);

            .a-button {
                @include font-base(18, 28);
                min-height: rem(44);

                border-radius: 12px;
                border: 2px solid var(--dkngaystroke, #ecbc79);
                background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

                &_wrapper {
                    background: none;
                }

            }

            @include tablet-down {
                max-width: rem(190);

                .a-button {
                    height: rem(36);
                    min-height: rem(36);
                    @include font-base(16, 24);
                }
            }
        }

        &_textarea {
            height: 100%;
            margin-top: rem(19);

            @include tablet-down {
                margin-top: rem(8);
                max-height: 60%;
            }

            textarea {
                background: $white;
                border: 1px solid #ddd;
                border-radius: 4px;
                box-shadow: none;
                font: inherit;
                height: 100%;
                max-height: rem(152);
                outline: 0;
                padding: rem(10) rem(13);
                resize: none;
                width: 100%;
                @include font-base(16, 24);

                @include tablet-down {
                    max-height: rem(88);
                    padding: 8px;
                    @include font-base(12, 18);
                }

                &::placeholder {
                    color: rgba(0, 0, 0, 0.4);
                }

                &:focus {
                    border-color: #2684ff;
                }
            }

            &.error {
                textarea {
                    border-color: #f00;

                    &::placeholder {
                        color: #f00;
                    }
                }
            }
        }
    }

    &_control {
        align-items: center;
        background-color: $white;
        display: flex;
        height: rem(253);
        justify-content: flex-end;
        padding: rem(16) rem(16) rem(12);
        position: relative;
        z-index: 4;
        @include width-left-side;

        @include tablet-down {
            height: rem(145);
            padding: rem(8);
        }

        &_wrapper {
            display: flex;
            height: 100%;
            margin-left: rem(-10);
            margin-right: rem(-10);
            width: 100%;

            @include tablet-down {
                margin-left: -4px;
                margin-right: -4px;
            }
        }

        &_pulldown {
            flex: 0 0 40%;
            max-width: 40%;
            padding-left: rem(10);
            padding-right: rem(10);

            .m-pulldown__value-container {
                input {
                    position: absolute;
                }
            }

            .m-pulldown__option {
                color: $pattrick-blue;

                &--is-focused {
                    color: $uni-cali-gold2;
                }
            }

            .m-pulldown__placeholder {
                @include font-base(16, 24);
                font-weight: 700;
                text-transform: uppercase;

                @include tablet-down {
                    @include font-base(10, 18);
                }
            }

            @include tablet-down {
                padding-left: 4px;
                padding-right: 4px;

                .m-pulldown__menu {
                    max-height: rem(92) !important;
                    overflow-y: auto;
                }

                .m-pulldown__option {
                    line-height: rem(16);
                    margin: rem(4) 0 0;
                }
            }

        }

        &_comment {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            padding-top: rem(16);
            position: relative;
        }

        &_error {
            // color: $kuCrimson;
            font-size: rem(14);
            margin-top: rem(4);
        }
    }

    // &_interactive {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     background-size: cover;
    //     background-position: center;
    //     padding: rem(12) rem(60) rem(12) rem(40);
    //     z-index: 2;
    //     @include tablet-down {
    //         .a-text {
    //             @include font-base(12, 12);
    //         }
    //     }

    //     &_left,
    //     &_right {
    //         position: absolute;
    //         width: 19.93%;
    //         height: 73.4%;
    //         bottom: 1%;
    //         background-size: contain;
    //         background-repeat: no-repeat;
    //         cursor: pointer;

    //         &_result {
    //             position: absolute;
    //             width: 15.4%;
    //             height: 55.43%;
    //             bottom: 9%;
    //             background-size: contain;
    //             background-repeat: no-repeat;
    //         }
    //     }

    //     &_left {
    //         left: 13.3%;
    //         background-position: right;

    //         &_result {
    //             left: 0;
    //             background-position: right;
    //         }
    //     }

    //     &_right {
    //         right: 13.3%;
    //         background-position: left;

    //         &_result {
    //             right: 0;
    //         }
    //     }

    //     &_countdown {
    //         position: absolute;
    //         margin: auto;
    //         top: rem(8);
    //         right: rem(8);
    //         height: 40px;
    //         width: 40px;
    //         text-align: center;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;

    //         &-number {
    //             color: #cc9439;
    //             display: inline-block;
    //             font-weight: bold;
    //             @include font-base(18, 20);
    //             margin-top: rem(4);

    //             @include tablet-down {
    //                 @include font-base(14, 16);
    //             }
    //         }
              
    //         &_circle {
    //             position: absolute;
    //             @keyframes dashed {
    //                 to {
    //                     transform: rotate(360deg);
    //                 }
    //             }
    //             height: 40px;
    //             top: 0;
    //             left: 0;
                  
    //             &::after,
    //             &::before {
    //                 border-radius: 50%;
    //                 content: '';
    //                 height: 34px;
    //                 left: 0;
    //                 position: absolute;
    //                 top: 0;
    //                 width: 34px;
    //             }
                  
    //             &::before {
    //                 -webkit-animation: dashed 1.5s linear infinite;
    //                 animation: dashed 1.5s linear infinite;
    //                 border: 3px dashed #cc9439;
    //                 border-bottom: 3px solid transparent;
    //                 border-left: 3px solid transparent;
    //                 z-index: 5;
    //             }
                  
    //             &::after {
    //                 -webkit-animation: dashed 1.5s ease infinite;
    //                 animation: dashed 1.5s ease infinite;
    //                 border: 3px solid #cc9439;
    //                 border-bottom: 3px solid transparent;
    //                 border-left: 3px solid transparent;
    //                 z-index: 10;
    //             }
                  
    //         }
    //     }
    // }
    &_interactive {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center bottom;
        padding: rem(16) rem(24) rem(48) rem(24);
        z-index: 101;
        background-image: url('~assets/images/livestream/bg_interactive_new.jpg');
        font-family: $font-brandon;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        @include mobile-lands {
            padding: rem(8) rem(8) rem(48);
        }

        @include mobile-down {
            padding: rem(8) rem(8) rem(48);
            background-image: url('~assets/images/livestream/bg_interactive_mobile.jpg');
            background-position: bottom;
        }

        &_header {
            display: flex;
            justify-content: space-between;
            flex-shrink: 0;
        }

        &_note {
            color: #bcbdbf;
            @include mobile-lands {
                p {
                    font-size: rem(12);
                    line-height: rem(16);
                }
            }
            @include mobile-down {
                p {
                    font-size: rem(10);
                    line-height: rem(12);
                }
            }
        }

        &_noted {
            flex-grow: 1;
            font-size: rem(6);
            line-height: rem(8);
            display: flex;
            align-items: flex-end;
            padding-top: rem(16);
        }

        &_countdown {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(240);
            height: rem(90);

            @include mobile-lands {
                width: fit-content;
                height: rem(44);
            }

            @include mobile-down {
                width: fit-content;
                height: rem(44);
            }

            &::before {
                content: '';
                position: absolute;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                background: linear-gradient(90deg, rgba(92, 94, 94, 1) 1%, rgba(213, 212, 212, 1) 14%, rgba(255, 255, 255, 1) 22%, rgba(240, 239, 239, 1) 30%, rgba(209, 209, 208, 1) 34%, rgba(132, 131, 131, 1) 41%, rgba(113, 113, 112, 1) 43%, rgba(255, 255, 255, 1) 64%, rgba(247, 247, 247, 0.88) 69%, rgba(237, 237, 237, 0.57) 81%, rgba(217, 216, 216, 0.09) 97%, rgba(213, 212, 212, 0) 100%);
                /* background: linear-gradient(270deg, #5c5d5c 0.81%, #d5d4c3 15.87%, #d6d6d5 28.92%, #717170 33.94%, #fffffb 39.96%, #fff 52.01%, #fff 56.03%, #d5d1bf 65.06%, #d5d5d5 83.13%, #cfcfcf 84.14%, #8d8b88 89.16%, #5c5954 94.17%, #3e3b34 98.19%, #332f28 100.2%); */
                top: -1px;
                left: -1px;
                z-index: 0;
                border-radius: rem(24);
                @include mobile-lands {
                    border-radius: rem(8);
                }
                @include mobile-down {
                    border-radius: rem(8);
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                /* background: linear-gradient(270deg, #005999 2.79%, #0d3964 98.51%); */
                background: linear-gradient(90deg, rgba(0, 90, 152, 1) 2.79%, rgba(74, 45, 128, 1) 98.51%);
                top: 0;
                left: 0;
                z-index: 0;
                border-radius: rem(24);
                @include mobile-lands {
                    border-radius: rem(8);
                }
                @include mobile-down {
                    border-radius: rem(8);
                }
            }

            &-text {
                position: relative;
                z-index: 1;
                color: $white;
                font-weight: bold;
                font-size: rem(20);
                line-height: rem(36);
                height: 100%;
                width: 100%;
                padding: rem(4);
                padding-left: rem(8);
                text-align: end;
                @include mobile-lands {
                    font-size: rem(12);
                    line-height: rem(14);
                }
                @include mobile-down {
                    font-size: rem(10);
                    line-height: rem(12);
                }
            }

            &-line {
                position: relative;
                z-index: 1;
                width: 2px;
                height: rem(80);
                border-radius: 1px;
                background: linear-gradient(180deg, #5c5d5c 0.5%, #fffffb 20.65%, #d5d4c3 40.81%, #f7f7f3 52.9%, #fff 55.92%, #d5d5d5 73.05%, #f3f3f3 82.12%, #fff 87.16%, #332f28 100.26%);
                margin-left: rem(4);
                margin-right: rem(4);
                @include mobile-lands {
                    height: rem(24);
                    margin-left: rem(4);
                    margin-right: rem(4);
                }
                @include mobile-down {
                    height: rem(24);
                    /* margin-left: rem(8);
                    margin-right: rem(8); */
                }
            }

            &-number {
                position: relative;
                z-index: 1;
                font-weight: bold;
                font-size: rem(72);
                padding-right: rem(16);
                @include text-background($linear-gold-interactive);
                @include mobile-lands {
                    font-size: rem(32);
                }
                @include mobile-down {
                    font-size: rem(32);
                    padding-right: rem(8);
                }
            }
        }

        &_question {
            position: relative;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            min-height: rem(120);
            padding: rem(16) rem(8) rem(20);
            flex-shrink: 0;
            @include mobile-lands {
                min-height: rem(38);
                padding-top: rem(0);
                padding-bottom: rem(12);
            }
            @include mobile-down {
                min-height: rem(38);
                padding-top: rem(12);
                padding-bottom: rem(8);
            }

            &_btmDeco {
                position: absolute;
                height: 2px;
                width: 25%;
                background: linear-gradient(90deg, #fff0, #ffffff20);
                bottom: -2px;
                right: 0;
            }

            &_questionSparkles {
                position: absolute;
                width: rem(200);
                bottom: rem(-4);
                right: 0;
            }

            &_wrapper {
                position: relative;
                padding: rem(8) rem(4);

                sup {
                    display: none;
                }

                &-full {
                    sup {
                        display: inline;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: calc(100% + 20px);
                    height: calc(100% + 20px);
                    background-color: #ffffff10;
                    top: -10px;
                    left: -10px;
                    z-index: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #ffffff10;
                    top: 0;
                    left: 0;
                    z-index: 0;
                    border-top: 2px solid #ffffff20;
                    border-right: 2px solid #ffffff20;
                }
            }

            &::before {
                content: '';
                position: absolute;
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                /* background: linear-gradient(90deg, #243872 -0.43%, #186db1 24.78%, #186db1 75.22%, #243872 100.43%); */
                top: -10px;
                left: -10px;
                z-index: 0;
                /* border-radius: rem(36);
                @include mobile-lands {
                    border-radius: rem(12);
                }
                @include mobile-down {
                    border-radius: rem(12);
                } */
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                /* background: linear-gradient(89.91deg, #c88b2f -11.62%, #ca8e33 -4.87%, #ce993e 1.89%, #d7ab50 8.64%, #e3c66c 14.27%, #e7cd75 26.65%, #f3e28c 44.66%, #faef9b 54.79%, #f7e893 63.8%, #efd67d 77.31%, #e1b959 94.19%, #dbac49 100.95%); */
                top: 0;
                left: 0;
                z-index: 0;
                /* border-radius: rem(36);
                @include mobile-lands {
                    border-radius: rem(12);
                }
                @include mobile-down {
                    border-radius: rem(12);
                } */
            }

            p {
                position: relative;
                font-size: rem(40);
                z-index: 1;
                @include mobile-lands {
                    font-size: rem(14);
                }
                @include mobile-down {
                    font-size: rem(22);
                }
            }

            &-title {
                /* position: absolute; */
                z-index: 1;
                /* width: rem(380); */
                width: 100%;
                height: rem(92);
                background: url('~assets/images/livestream/bg_question_new.png') no-repeat;
                background-size: contain;
                background-position: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: rem(16);
                /* top: rem(-46);
                left: 50%; */
                /* transform: translateX(-50%); */
                @include mobile-lands {
                    /* width: rem(280); */
                    height: rem(48);
                    /* top: rem(-26); */
                }
                @include mobile-down {
                    /* width: rem(280); */
                    height: rem(48);
                    /* top: rem(-26); */
                }

                p {

                    @include mobile-down {
                        font-size: rem(20);
                    }
                }
            }
        }

        &_answers {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            justify-content: space-evenly;
            margin-top: rem(20);
            flex-shrink: 0;

            @include mobile-lands {
                width: 100%;
                margin-top: rem(4);
            }
            
        }

        &_answer {
            position: relative;
            flex-basis: calc(50% - 32px);
            cursor: pointer;
            margin-top: rem(40);
            @include mobile-lands {
                margin-top: rem(10) !important;
                flex-basis: 40% !important;
            }
            @include mobile-down {
                flex-basis: 100%;
                margin-top: rem(12);
            }

            &_content {
                width: 100%;
                @include aspect-ratio(515,102);
                background: url('~assets/images/livestream/bg_answer_new.png') no-repeat;
                background-size: contain;

                &-selected {
                    background-image: url('~assets/images/livestream/bg_answer_selected_new.png');
                }

                &-corrected {
                    background-image: url('~assets/images/livestream/bg_answer_correct_new.png');
                }
            }

            &_alpha {
                position: absolute;
                width: 14.5%;
                z-index: 2;
                left: 2.5%;
                top: 14%;

                p {
                    position: absolute;
                    width: 100%;
                    height: 0;
                    @include aspect-ratio(1, 1);
                    background: url('~assets/images/livestream/bg_answer_alpha_new.png') no-repeat;
                    background-size: contain;
                    font-size: rem(60);
                    
                    @include mobile-lands {
                        font-size: rem(34);
                    }

                    @include mobile-down {
                        font-size: rem(32);
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin-top: rem(4);
                    }
                }
            }

            &_text {
                z-index: 1;
                position: absolute;
                width: 96.65%;
                left: 50%;
                height: 84.5%;
                padding-left: 19%;
                padding-right: 4%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: rem(32);
                display: flex;
                align-items: center;

                @include break-max(1366px) {
                    font-size: rem(24);
                }
                @include mobile-lands {
                    font-size: rem(14) !important;
                }
                @include mobile-lands-small {
                    font-size: rem(12) !important;
                }
                @include mobile-down {
                    font-size: rem(15);
                    line-height: rem(16);
                }

                &-selected {
                    p {
                        color: $white;
                    }
                }

                &-corrected {
                    p {
                        color: $us-blue;
                        text-shadow: none;
                    }
                }

                span {
                    // background: linear-gradient(29.91deg, #1f386b 6%, #1a65a2 32%, #1782c5 47%, #1973b3 59%, #1d4c84 83%, #1f386b 95%);
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                    sup {
                        display: none;
                    }

                    &.full {
                        sup {
                            display: inline;
                        }
                    }
                }
            }
        }
    }
}

.o-liveComment {
    &_item {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
        margin-top: rem(20);
        padding: rem(16) rem(20);

        @include tablet-down {
            margin-top: rem(12);
            padding: rem(12);
        }

        &:first-child {
            margin-top: 0;
        }
    }
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 200;
    }
}
