.a-input {
    $root: &;
    $r: 5px; // radius
    position: relative;
    width: 100%;

    &-border {
        #{$root}_inputele {
            border: 1px solid $gray-x112;
            border-radius: 5px;
        }
    }

    &-h56 {
        input {
            height: rem(56);

            @include tablet-down {
                height: rem(44);
                @include font-base(14, 18);
            }
        }
    }

    &-h60 {
        input {
            height: rem(60);
            @include font-base(24, 32);

            @include tablet-down {
                height: rem(44);
                @include font-base(21, 28);
            }
        }
    }

    &_errorMessage {
        color: $light-brilliant-red;
        margin-top: rem(4);
        text-align: center;

        span {
            @include font-base(24, 28);

            @include tablet-down {
                @include font-base(20, 25);
            }

            @include mobile-down {
                @include font-base(14, 21);
            }
        }

        &-left {
            text-align: left;
        }

        &-right {
            text-align: right;
        }

        &-center {
            text-align: center;
        }
    }

    &_label {
        margin-bottom: rem(2);
    }

    &_required {
        margin-left: rem(4);
        color: $light-brilliant-red;
        font-size: inherit;
    }

    &_container {
        position: relative;
        overflow: hidden;
    }

    &-h44 {
        #{$root}_inputele {
            height: rem(44);
            @include font-base(16, 24);
        }
    }

    &-h48 {
        #{$root}_inputele {
            height: rem(48);
            @include font-base(16, 24);
        }
    }

    &-h52 {
        #{$root}_inputele {
            height: rem(52);
            @include font-base(20, 32);

            @include mobile-down {
                height: rem(44);
                @include font-base(16, 24);

            }
        }
    }

    &-otp {
        height: rem(48);
        width: rem(48);

        @include mobile-down {
            height: rem(32);
            width: rem(32);
        }

        #{$root}_inputele {
            border: 1px solid $light-gray;
            border-radius: rem(4);
            color: $oxford-blue;
            height: 100%;
            padding: 0;
            text-align: center;
            width: 100%;
            color: $pattrick-blue;
            font-weight: 500;

            @include mobile-up {
                @include font-base(24, 32);
            }
        }

        #{$root}_container {
            height: 100%;
            width: 100%;
            background: none;
        }
    }

    &_inputele {
        appearance: none;
        color: $black;
        border: 1px solid $gray-x112;
        border-radius: $r - 1;
        margin: 0;
        outline: none;
        padding: 0 rem(22);
        transition: all $transition linear;
        width: 100%;
        font-family: inherit;
        @include font-base(20, 34);

        &:not(#{$root}-otp) & {
            border: 0;
            border-radius: rem(8);
            padding: rem(14) rem(20);
        }

        @include tablet-down {
            padding: rem(2) rem(16);
        }

        &-error {
            border-color: $red;
        }

        &-noCursor {
            pointer-events: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            display: none;
        }

        &::placeholder {
            color: rgba($black, 0.4);
        }
    }

    &-prefix {
        #{$root}_inputele {
            padding-left: rem(62);
        }
    }

    &_prefix {
        position: absolute;
        top: rem(9);
        left: rem(10);
        color: $black;
        @include font-base(20, 34);
    }

    &-login {
        #{$root}_inputele {
            height: rem(52);
            @include font-base(20, 32);

            @include max-large-desktop-down {
                height: rem(48);
            }

            @include max-desktop-down {
                height: rem(44);
            }

            @include mobile-down {
                height: rem(44);
                @include font-base(16, 24);

            }
        }

        #{$root}_errorMessage {
            color: $light-brilliant-red;
            margin-top: rem(4);
    
            span {
                @include font-base(20, 28);

                @include max-large-desktop-down {
                    @include font-base(18, 24);
                }
    
                @include tablet-down {
                    @include font-base(16, 21);
                }
    
                @include mobile-down {
                    @include font-base(14, 19);
                }
            }

        }
    }
}
