.p-waiting {
    position: relative;

    &_background {
        .a-image {
            @include tablet-down {
                @include aspect-ratio(383, 851);
            }
        }
    }

    &_text {
        left: 8%;
        max-width: rem(528);
        position: absolute;
        top: 20%;
        width: 100%;
        @include break-min($standard-viewport) {
            max-width: 528 * $w;
        }
        @include desktop-down {
            max-width: rem(384);
        }
        @include tablet-down {
            left: 50%;
            max-width: rem(350);
            top: 50%;
            transform: translateX(-50%);
        }

        img {
            width: 100%;
        }
    }
}
