.o-liveComment {
    &_item {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 4px 4px rgba($black, 0.05);
        margin-top: rem(20);
        padding: rem(16) rem(20);

        @include tablet-down {
            margin-top: rem(12);
            padding: rem(12);
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &_comments {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1.25rem 0.875rem;
        position: absolute;
        right: 0;
        top: 0;
        transition: $transition-long ease;
        width: 32vw;
        z-index: 10;

        @include desktop-down {
            padding: rem(12);
            width: 32vw;
        }

        @include desktop-down {
            width: 28vw;
        }

        &_head {
            @include break-min(570px) {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-left: -4px;
                margin-right: -4px;
            }

            @include tablet-down {
                display: block;
            }

            .name {
                display: flex;
                flex: 1 0 0;
                padding-left: 4px;
                padding-right: 4px;

                .a-icon {
                    flex: 0 0 rem(24);
                    margin-right: 7px;

                    @include tablet-down {
                        flex: 0 0 rem(16);
                        margin-right: 3px;
                    }
                }

                .a-text {
                    @include text-background($linear-seal-brown);

                    @include desktop-down {
                        @include font-base(14, 20);
                    }

                    @include mobile-down {
                        @include font-base(12, 17);
                    }
                }
            }

            .time {
                opacity: 0.55;
                padding-left: 4px;
                padding-right: 4px;

                @include tablet-down {
                    margin-left: rem(20);
                    text-align: right;
                }

                .a-text {
                    color: $onyx055;

                    @include desktop-down {
                        @include font-base(11, 17);
                    }
                }
            }
        }

        &_icon {
            align-items: center;
            display: flex;
            margin-right: rem(7);
        }

        &_content {
            .a-text {
                color: $onyx;

                @include desktop-down {
                    @include font-base(12, 18);
                }
            }
        }
    }
}
