.m-backgroundTitle {
    $root: &;
    text-align: center;

    &-secondary {
        #{$root}_wrap {
            background: $linear-california-gold2;
            position: relative;

            &::before {
                background: $linear-california-gold2;
                border-radius: rem(60);
                bottom: -3px;
                content: '';
                left: -3px;
                position: absolute;
                right: -3px;
                top: -3px;
                z-index: $z-1;
            }

            &::after {
                background: $linear-california-gold;
                border-radius: rem(60);
                bottom: rem(-6);
                content: '';
                left: rem(-6);
                position: absolute;
                right: rem(-6);
                top: rem(-6);
                z-index: 0;
            }
        }
    }

    &-survey {
        #{$root}_wrap {
            background: linear-gradient(90deg,
            rgba(241, 231, 204, 0) 0%,
            #f1e7cc 49.23%,
            rgba(241, 231, 204, 0) 100%);
            position: relative;
            padding: rem(16) 0 rem(6);
            @include tablet-down {
                padding: rem(8) 0;
            }

            &::before {
                background: linear-gradient(to left,
                #d5880000 0%,
                #f2aa00 30.36%,
                #d58800 50.88%,
                #d7aa5c 75.76%,
                #c88c2c00 100%);
                content: '';
                height: 3px;
                left: 50%;
                position: absolute;
                top: 0;
                transform: translateX(-50%);
                width: 60%;
            }

            &::after {
                background: linear-gradient(to left,
                #d5880000 0%,
                #f2aa00 30.36%,
                #d58800 50.88%,
                #d7aa5c 75.76%,
                #c88c2c00 100%);
                bottom: 0;
                content: '';
                height: 3px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 50%;
            }
        }
    }

    &_wrap {
        background: linear-gradient(90deg,
        #80c4bf 0.15%,
        #a3ded8 18.3%,
        #c1e0de 33.94%,
        #bee0de 62.16%,
        #a9d2cd 72.85%,
        #7bc5c0 100%);
        border-radius: rem(60);
        display: inline-block;
        padding: rem(6) rem(60);
        position: relative;

        @include tablet-down {
            padding: rem(6) rem(30);
        }

        .a-heading {
            position: relative;
            z-index: $z-2;
        }
    }
}
