.o-player {
    position: relative;

    video {
        background: $black;
    }

    &_layer {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &_fullscreen {
        align-items: center;
        bottom: rem(12);
        cursor: pointer;
        display: flex;
        height: rem(36);
        justify-content: center;
        position: absolute;
        right: rem(24);
        width: rem(36);
        z-index: 8;
        background: rgba($black, 0.5);
        border-radius: rem(8);
    }

    &_sound {
        align-items: center;
        bottom: rem(12);
        cursor: pointer;
        display: flex;
        height: rem(36);
        justify-content: center;
        left: rem(24);
        position: absolute;
        width: rem(36);
        z-index: 9;
        background: rgba($black, 0.5);
        border-radius: rem(8);

        @include desktop-down {
            left: rem(16);
        }

        @include mobile-down {
            left: rem(12);
        }

        .m-volumeControl {
            min-width: unset;

            &-muted {
                i.icon {
                    height: 30px !important;
                    width: 30px !important;
                }
            }

            i.icon {
                height: 24px;
                width: 24px;
            }
        }
    }
}
