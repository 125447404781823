.t-forgotPasswordForm {
    max-width: rem(640);
    margin: 0 auto;

    &_subTitle {
        margin-bottom: rem(12);
    }

    &_button {
        margin: rem(24) auto 0;
    }

    &_form {
        margin: rem(4) auto 0;
    }

    &_wrapRegister {
        @include adjust-flex();
        margin-top: rem(12);
    }

    &_register {
        margin-left: rem(4);
        cursor: pointer;

        .a-text {
            @include text-background($linear-seal-brown); }
    }

    &_input {
        .a-input_errorMessage {
            span {
                @include font-base(20,32);
                @include mobile-down {
                    @include font-base(16,24);
                }
            }
        }
    }

    &_noti {
        width: fit-content;
        // display: inline-block;
        padding-top: rem(4);
        margin-top: rem(12);
        margin-bottom: rem(12);
        margin-left: auto;
        margin-right: auto;
        background: linear-gradient(90deg, rgba(250, 250, 250, 0) 1%, #fafafa 10%, #cdcdcd 28%, #eaeaea 41%, #fafafa 51%, #ececec 58%, #cecece 70%, #fafafa 90%, rgba(250, 250, 250, 0) 100%);

        @include tablet-down {
            padding-top: rem(2);
        }
    }
}
