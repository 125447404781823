.m-countdown {
    align-items: center;
    display: inline-flex;

    &-timeleft {
        background: url('~assets/images/layer_countdown.png') no-repeat;
        background-size: cover;
        // background: $linear-cali-gold;
        height: rem(80);
        width: rem(80);
        box-shadow: 0 4px 4px rgba($black, 0.25);

        @include break-min($standard-viewport) {
            height: 80 * $w;
            width: 80* $w;
        }

        @include desktop {
            height: rem(65);
            width: rem(65);
        }

        @include mobile-down {
            height: rem(52);
            width: rem(52);
        }

        &:not(:last-child) {
            margin-right: rem(16);

            @include break-min($standard-viewport) {
                margin-right: 16 * $w;
            }
        }
    }

    &_wrapper {
        flex-direction: column;
        height: 100%;
        width: 100%;
        @include adjust-flex(center, center);
    }

    &-time {
        @include text-background($linear-seal-brown5);
        @include font-base(40, 48);
        font-weight: 700;
        transform: translateY(8px);

        @include break-min($standard-viewport) {
            font-size: 40 * $w;
            line-height: 48 * $w;
        }

        @include tablet-down {
            transform: translateY(4px);
        }

        @include desktop {
            @include font-base(30, 42);
        }

        @include mobile-down {
            @include font-base(24, 33);
        }
    }

    &-unit {
        color: $pattrick-blue;
        font-weight: 700;
        text-transform: uppercase;
        @include font-base(16, 24);

        @include break-min($standard-viewport) {
            font-size: 16 * $w;
            line-height: 24 * $w;
        }

        @include desktop {
            @include font-base(14, 26);
        }

        @include mobile-down {
            @include font-base(12, 20);
        }
    }
}
