.t-backgroundPanel {
    $root: &;

    &_wrap {
        align-items: center;
        background: linear-gradient(90deg, #b2dad8 0%, #e5f5f0 15.47%, #e5f5f0 70.99%, #aad7d4 99.99%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: rem(40);
        padding-top: rem(40);
    }

    &-secondary {
        #{$root}_content {
            margin-top: 0;

            .a-text-20x32 {
                @include tablet-down {
                    @include font-base(14, 22);
                }
            }

            .a-heading-28x40,
            .a-heading-32x48 {
                @include tablet-down {
                    @include font-base(18, 26);
                }
            }

            .a-heading-40x48 {
                @include tablet-down {
                    @include font-base(22, 38);
                }
            }

            .a-text-24x32 {
                @include tablet-down {
                    @include font-base(18, 26);
                }
            }

            .a-heading-52x70 {
                @include tablet-down {
                    @include font-base(26, 45);
                }
            }
        }
    }

    &_content {
        position: relative;
        z-index: $z-2;

        .block {
            .a-heading {
                span.break {
                    @include mobile-down {
                        display: block;
                    }
                }
            }
        }
    }

    &_button {
        align-items: center;
        display: flex;
        justify-content: space-around;
        margin: rem(60) auto 0;
        max-width: rem(718);

        @include tablet-down {
            margin-top: rem(28);
        }

        .a-button {
            width: rem(310);

            @include tablet-down {
                width: rem(280);
            }
        }
    }
}
