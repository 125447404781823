.t-cmeNotifyPopup {
    .block2 {
        margin-top: rem(24);
        @include tablet-down {
            margin-top: rem(18);
        }
    }

    .block3 {
        margin-top: rem(16);
        @include tablet-down {
            margin-top: rem(12);
        }
    }

    .block4 {
        margin-top: rem(40);
        @include tablet-down {
            margin-top: rem(28);
        }
    }
}
