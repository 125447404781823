.m-otpInput {
    $root: &;
    position: relative;

    @include mobile-down {
        margin-bottom: rem(20);
    }

    &_wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: rem(20);
    }

    &.error {
        input {
            
            border: 1px solid $ku-crimson;
        }
    }

    .a-input {
        position: relative;
        margin-left: rem(8);
        margin-right: rem(8);
        font-weight: bold;

        &::before {
            content: attr(data-index);
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            background: linear-gradient(30deg, rgba(26, 103, 171, 0) 0.68%, rgba(27, 98, 166, 0.13) 4.63%, rgba(29, 86, 153, 0.46) 13.5%, #214184 26.32%, #1a67ab 52.95%, #1d579a 63.8%, #214184 76.62%, rgba(33, 67, 134, 0.95) 79.58%, rgba(32, 72, 139, 0.81) 84.51%, rgba(30, 81, 148, 0.58) 89.44%, rgba(28, 93, 161, 0.27) 95.36%, rgba(26, 103, 171, 0) 99.3%);
            border-radius: rem(4);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $anti-flash-white;
            border-radius: rem(4);
        }

        input {
            position: relative;
            z-index: 1;
            border: 0;
            background: none;
        }

        @include tablet-down {
            margin-left: rem(7);
            margin-right: rem(7);
        }
    }

    &_error {
        color: $ku-crimson;
        margin-top: rem(8);
    }
}
