.o-tfcplayer {
    background-color: $black;
    height: 100%;
    position: relative;
    width: 100%;

    video {
        height: 100%;
        width: 100%;
    }

    &_liveIcon {
        bottom: rem(15);
        height: rem(24);
        left: rem(54);
        position: absolute;
        z-index: 5;
        cursor: pointer;
        @include tablet-down {
            left: 4%;
        }
    
        span {
            display: block;
            font-weight: bold;
            line-height: rem(24);
      
            &.islive {
                color: $red;
            }
      
            &.delay {
                color: $battleship-gray;
                cursor: pointer;
            }
        }
    }

    &_layer {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &_loading {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img {
            height: rem(60);
            width: rem(60);
        }
    }
}
