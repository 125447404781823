%flex-triple {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.t-register {
    $root: &;
    background: linear-gradient(129.01deg, #fff 0.58%, #fdedc6 25.29%, #fdedc6 71.75%, #fff 99.43%) padding-box, $linear-main-border-blue-2025 border-box;
    border: 2px solid transparent;
    border-radius: rem(20);
    padding: rem(60) rem(40) rem(40);
    position: relative;

    @include tablet-down {
        padding: rem(32) rem(24) rem(24);
    }

    @include mobile-down {
        padding: rem(32) rem(16) rem(16);
    }

    @include small-mobile {
        padding: rem(32) rem(14) rem(16);
    }

    &_heading {
        position: absolute;
        height: rem(60);
        top: rem(-30);
        left: 50%;
        padding: rem(8) rem(60) rem(0) rem(60);
        border-radius: rem(30);
        transform: translateX(-50%);
        display: flex;
        align-items: center;

        @include tablet-down {
            height: rem(40);
            top: rem(-20);
            padding-left: rem(16);
            padding-right: rem(16);
            padding-top: rem(4);
        }

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            background: $linear-decor-2025;
            border-radius: rem(30);
            z-index: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $linear-main-2025;
            border-radius: rem(30);
            z-index: 0;
        }

        .a-heading {
            position: relative;
            z-index: 1;
            white-space: nowrap;
        }
    }

    &-popup {
        padding: rem(30) rem(0);

        @include desktop-down {
            padding: rem(30);
        }

        @include mobile-down {
            padding: rem(10) rem(16) rem(16);
        }

        #{$root}_wrapper {
            max-width: rem(660);
        }

        #{$root}_noti {
            padding: 0;

            .a-text {
                @include font-base(28, 40);

                @include max-large-desktop-down {
                    @include font-base(24, 30);
                }

                @include max-desktop-down() {
                    @include font-base(21, 28);
                }

                @include desktop-down() {
                    @include font-base(20, 26);
                }

                @include tablet-down {
                    @include font-base(18, 26);
                }

                @include mobile-down {
                    @include font-base(16, 22);
                }
            }
        }

        #{$root}_term {
            .a-text {
                @include font-base(28, 40);

                @include max-large-desktop-down {
                    @include font-base(24, 30);
                }

                @include max-desktop-down() {
                    @include font-base(21, 28);
                }

                @include desktop-down() {
                    @include font-base(20, 26);
                }

                @include tablet-down {
                    @include font-base(18, 26);
                }

                @include mobile-down {
                    @include font-base(16, 22);
                }
            }

            &-seemore {
                @include font-base(28, 40);
    
                @include max-large-desktop-down {
                    @include font-base(24, 30);
                }

                @include max-desktop-down() {
                    @include font-base(21, 28);
                }

                @include desktop-down() {
                    @include font-base(20, 26);
                }

                @include tablet-down {
                    @include font-base(18, 26);
                }

                @include mobile-down {
                    @include font-base(16, 22);
                }
            }
        }

        #{$root}_input {
            @include mobile-up {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        #{$root}_introduce {
            &-title {
                .a-heading {
                    @include font-base(36, 58);
    
                    @include max-large-desktop-down {
                        @include font-base(26, 38);
                    }
            
                    @include max-desktop-down {
                        @include font-base(22, 32);
                    }
            
                    @include tablet-down {
                        @include font-base(20, 30);
                    }
            
                    @include mobile-down {
                        @include font-base(18, 26);
                    }
            
                    @include small-mobile {
                        @include font-base(16, 24);
                    }
                }
            }

            &-note {
                .a-text {
                    @include font-base(28, 40);

                    @include max-large-desktop-down {
                        @include font-base(24, 30);
                    }
            
                    @include max-desktop-down() {
                        @include font-base(21, 28);
                    }
            
                    @include desktop-down() {
                        @include font-base(20, 26);
                    }
            
                    @include tablet-down {
                        @include font-base(18, 26);
                    }
            
                    @include mobile-down {
                        @include font-base(16, 22);
                    }
                }
            }
        }

        #{$root}_login {
            @include adjust-flex(center, center);

            .register {
                cursor: pointer;
                margin-left: rem(7);
            }
        }

        .a-input {
            &_label {
                .a-text {
                    @include font-base(18, 28);
                    text-transform: uppercase;
                    white-space: nowrap;

                    @include mobile-down {
                        @include font-base(16, 24);
                    }
                }
            }

            &_inputele {
                font-family: 'Arial';
            }

            &_required {
                color: $ku-crimson !important;
            }
        }

    }

    &_form {
        display: flex;
        flex-wrap: wrap;
        margin: rem(-8) rem(-16);

        @include small-mobile {
            margin: rem(-8) 0;
        }
    }

    &_input {
        @extend %flex-triple;
        padding: rem(8) rem(16);

        @include tablet-down {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include small-mobile {
            flex: 0 0 100%;
            padding: rem(8) 0;
            max-width: 100%;
        }

        .a-input_required {
            color: $pattrick-blue;
        }
    }

    &_button {
        max-width: rem(270);
        margin: rem(24) auto 0;

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }

        span {
            @include font-base(20, 32);

            @include mobile-down {
                @include font-base(16, 24);

            }
        }

        @include mobile-down {
            max-width: rem(190);
            margin: rem(24) auto 0;

            padding-top: 0;

        }
    }

    &_wrapRegister {
        @include adjust-flex();
        margin-top: rem(16);

        @include mobile-down {
            margin-top: rem(6);
        }
    }

    &_register {
        margin-left: rem(4);
        cursor: pointer;

        .a-text {
            @include text-background($linear-seal-brown);
        }
    }

    &_link {
        cursor: pointer;
        margin-top: rem(8);
    }

    &_policy {
        margin-top: rem(24);

        @include mobile-down {
            max-width: rem(330);
            margin: rem(24) auto 0;

            .a-text {
                @include font-base(16, 24);
            }

            .mt-1 {
                margin-top: rem(8) !important;
            }
        }
    }

    &_wrapper {
        max-width: 86%;
        margin-left: auto;
        margin-right: auto;

        @include max-desktop-down {
            max-width: 92%;
        }

        @include mobile-down {
            max-width: 100%;
        }
    }

    &_notiWrap {
        display: flex;
        justify-content: center;
        margin-top: rem(24);
    }

    &_noti {
        display: inline-block;
        padding-left: rem(120);
        padding-right: rem(120);
        padding-top: rem(4);
        background: linear-gradient(90deg, rgba(250, 250, 250, 0) 1%, #fafafa 15%, #cdcdcd 28%, #eaeaea 41%, #fafafa 51%, #ececec 58%, #cecece 70%, #fafafa 87%, rgba(250, 250, 250, 0) 100%);

        @include tablet-down {
            padding-left: rem(32);
            padding-right: rem(32);
            padding-top: rem(2);
        }

        @include mobile-down {
            padding-left: rem(16);
            padding-right: rem(16);
        }
    }

    &_formWrapper {
        &-field {
            max-width: rem(765);
            margin-left: auto;
            margin-right: auto;
            margin-top: rem(24);
        }
    }

    &_term {
        margin-top: rem(16);

        &_checkbox {
            display: flex;
            justify-content: center;
            margin-top: rem(12);
        }

        &-button {
            &-loading {
                pointer-events: none;
            }
        }
    }

    &_error {
        margin-top: rem(8);
    }

    &_introduce {
        position: relative;
        margin-top: rem(40);

        p {
            margin-top: rem(16);

            @include mobile-down {
                margin-top: rem(12);
            }
        }

        &-title {
            position: relative;

            &::before {
                background: linear-gradient(90deg, rgba(203, 152, 49, 0) 0%, rgba(242, 217, 141, 0.8753) 23.47%, #f8e29a 26.81%, #e0ae31 59.71%, #eed88c 79.76%, #f3e8af 87.07%, rgba(224, 174, 49, 0) 99.98%);
                bottom: 0;
                content: '';
                height: rem(3);
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                width: 45%;

                @include mobile-down {
                    width: 55%;
                }
            }
        }

        &-wrapNote {
            padding: 0;
            margin: 0;

            @include mobile-down {
                padding-left: rem(12);
            }
        }

        &-note {
            &::marker {
                width: rem(10);
                height: rem(10);
                color: #1b4685;
            }
        }
    }
}