.m-rating {
    display: flex;
    justify-content: space-between;
    padding: 0 rem(32) rem(40);
    position: relative;

    &_star {
        position: relative;
    }

    &_label {
        bottom: rem(-35);
        color: $black;
        font-weight: bold;
        position: absolute;
        text-align: center;
        white-space: nowrap;

        @include font-base(24, 32);
        @include tablet-down {
            @include font-base(10, 15);
            width: rem(54);
        }

        &-left {
            left: rem(-72);
            @include break-min($standard-viewport) {
                left: -72 * $w;
            }
            @include break-min(1800px) {
                left: -60 * $w;
            }
        }

        &-right {
            left: rem(-68);
            @include break-min($standard-viewport) {
                left: -68 * $w;
            }
            @include break-min(1800px) {
                left: -30 * $w;
            }
        }
    }

    .star {
        background: transparent url('~assets/icons/star.svg') no-repeat center/100%
            auto;
        cursor: pointer;
        height: rem(80);
        width: rem(80);
        @include desktop-down {
            height: rem(60);
            width: rem(60);
        }
        @include tablet-down {
            height: rem(35);
            width: rem(35);
        }
    }

    .star.selected {
        background-image: url('~assets/icons/starSelected.svg');
    }
}
