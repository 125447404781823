.t-testimonials {
    $root: &;
    $sizeImg: 288px;
    $sizeImgTablet: 220px;
    $sizeImgMobile: 154px;

    &_containerFirst {
        margin-top: rem(48);

        @include tablet-down {
            margin-top: rem(22);
        }
    }

    &_containerSecond {
        margin-top: rem(16);

        @include tablet-down {
            margin-top: rem(22);
        }
    }

    &_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: rem(16);

        @include tablet-down {
            margin-top: 0;
        }

        &-two {
            .t-testimonials_item {
                @include min-large-desktop-down {
                    flex: 0 0 calc(1 / 3 * 100%);
                    max-width: calc(1 / 3 * 100%);
                }

                flex: 0 0 calc(1 / 2.5 * 100%);
                max-width: calc(1 / 2.5 * 100%);


                @include tablet-down {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @include mobile-down {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &_item {
        flex: 0 0 calc(1 / 3 * 100%);
        max-width: calc(1 / 3 * 100%);

        @include tablet-down {
            margin-top: rem(28);
        }

        @include tablet-down {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
        }

        &_inner {
            position: relative;
            text-align: center;
            max-width: 92%;
            margin: auto;

            @include max-large-desktop-down {
                max-width: 98%;
            }
        }

        &_wrapImg {
            position: relative;

            margin: auto;
            max-width: $sizeImg;

            @include mobile-down {
                max-width: $sizeImgMobile;
            }
        }

        // &_role,
        // &_position {
        //     .a-text {
        //         @include mobile-down {
        //             @include font-base(16, 28); 
        //         }

        //         @media (max-width: 374px) {
        //             @include font-base(14, 24); 
        //         }
        //     }
        // }

        &_name {
            .a-text {

                @include mobile-down {
                    @include font-base(20, 36);
                }
            }

        }

        &_img {
            img {
                object-fit: contain;
            }
        }

    }

    &_title {
        margin-top: rem(16);
        width: fit-content;
        margin: 0 auto;
        background: linear-gradient($white, $white) padding-box, linear-gradient(to right, $peru, $mellow-apricot) border-box;
        border: 2px solid transparent;
        padding: rem(6) rem(24) 0;
        border-radius: rem(26);
        height: rem(48);
        display: flex;
        align-items: center;

        @include mobile-down {
            padding-top: rem(6);
        }

        .a-text {
            @include text-background($linear-blue-text);
        }
    }

    &_heading {
        @include small-mobile {
            .a-heading-40x56 {
                @include font-base(22, 36);
            }
        }
    }
}
