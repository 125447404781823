.a-checkbox {
    $root: &;

    &-light {
        #{$root}_square {
            border-color: $white;
        }

        #{$root}_main input {
            &:checked~.a-checkbox_square {
                &::before {
                    border-color: $white;
                }
            }
        }
    }

    &_main {
        cursor: pointer;
        position: relative;
        @include adjust-flex(flex-start, center);

        input {
            position: absolute;
            visibility: hidden;
            width: 0;

            &:checked~.a-checkbox_square {
                &::before {
                    border-bottom: 4px solid $pigment;
                    border-right: 4px solid $pigment;
                    content: '';
                    height: rem(15);
                    left: 6px;
                    position: absolute;
                    top: 0;
                    transform: rotate(45deg);
                    width: rem(8);


                    @include mobile-down {
                        left: rem(5);
                        height: rem(8);
                        width: rem(3);
                    }
                }
            }
        }

        &_label {
            cursor: pointer;
            flex-grow: 1;
            padding: rem(6) rem(24);
            position: relative;

            p {
                line-height: rem(26);
                padding-left: rem(14);
                position: relative;
                z-index: 1;

                @include mobile-down {
                    @include font-base(13, 18);
                    padding-left: rem(2);
                }
            }
        }
    }

    &_square {
        border: 2px solid $pigment;
        border-radius: 5px;
        box-sizing: border-box;
        height: rem(28);
        left: 0;
        position: absolute;
        width: rem(28);

        @include mobile-down {
            width: rem(20);
            height: rem(20);
        }
    }

    &_error {
        color: $red;
        margin-top: rem(4);
        @include font-base(24, 28);

        @include tablet-down {
            @include font-base(20, 25);
        }

        @include mobile-down {
            @include font-base(14, 21);
        }
    }
}
