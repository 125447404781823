.t-banner {
    $root: &;
    position: relative;
    overflow: hidden;
    // &-fullheight {
    //     #{$root}_background {
    //         @include iphone-large-lands {
    //             height: inherit !important;
    //         }
    //     }
    // }

    &-isCenter {
        #{$root}_wrapper {
            @include adjust-flex();
        }
    }

    &_background {
        height: 100%;
        background: $linear-bg-2025;
        // overflow-y: auto;

        @include mobile-down {
            background: $linear-bg-mb-2025;
        }

        @include iphone-large-lands {
            height: auto;
        }
    }

    &-isHorizontal {
        #{$root}_wrapper {
            padding: rem(24) rem(16);
        }

        #{$root}_relative {
            height: 100%;
            overflow-y: auto;

            @include mobile-up {
                @include adjust-flex();
                flex-direction: column;
            }
        }

        #{$root}_background {
            .a-image {
                padding-bottom: 63%;
            }
        }

        #{$root}_heading {
            max-width: 70%;
        }

        .t-bannerHome {
            &_logo {
                margin: 0 auto rem(8);

                &_item {

                    width: rem(76);
                    height: rem(35);

                    &:first-child {
                        width: rem(35);
                        height: rem(35);
                    }
                }
            }

            &_datetime {
                @include tablet-down {
                    width: 85%;
                }

                .a-text {
                    @include font-base(16, 24);
                }
            }

            &_countdown {
                margin-top: rem(8);

                &_title {
                    .a-text {
                        @include font-base(16, 24);
                    }
                }

                .m-countdown {
                    margin-top: rem(4);

                    &-time {
                        @include font-base(24, 33);
                    }

                    &-timeleft {
                        height: rem(56);
                        width: rem(56);

                        &:not(:last-child) {
                            margin-right: rem(16);
                        }
                    }

                    &-unit {
                        @include font-base(12, 20);
                    }
                }
            }

            &_button {
                max-width: rem(220);

                .a-button {
                    height: rem(36);
                }
            }
        }
    }


    &:not(.t-banner-isHorizontal) {
        #{$root}_wrapper {
            @include tablet-down {
                width: 100%;
                min-width: inherit;
                top: 0;
                padding: rem(12) 0 rem(12);
                height: 100%;
            }
        }
    }

    &_relative {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &_wrapper {
        position: relative;
        width: 100%;
        transition: $transition ease-in;
        padding-bottom: rem(12);
    }

    &_logo {
        width: 100%;
        padding: rem(24) rem(60) rem(20);
        @include adjust-flex(space-between, center);

        @include mobile-down {
            padding: 0 rem(16);
        }

        &_item {
            width: rem(76);
            margin-left: rem(24);
            margin-right: rem(24);

            &:first-child {
                width: rem(92);
            }

            &:last-child {
                width: rem(351);
            }
        }
    }

    &_heading {
        width: 100%;
        max-width: 80%;
        margin: 0 auto;

        @include break-min($standard-viewport) {
            max-width: 440 * $w;
        }

        @include mobile-down {
            min-width: rem(273);
            max-width: rem(330);
        }

        img {
            width: 100%;
        }
    }

    &_link {
        margin-top: rem(16);

        @include mobile-down {
            margin-top: rem(12);
        }
    }

    &_contentCustom {
        // left: 4%;
        // max-width: rem(428);
        // position: absolute;
        // top: 21%;
        // width: 100%;

        // @include break-min($standard-viewport) {
        //     left: 7%;
        //     max-width: 428 * $w;
        // }

        // @include tablet-down {
        //     left: 50%;
        //     max-width: rem(603);
        //     top: 52%;
        //     transform: translateX(-50%);
        // }

        // @include mobile-down {
        //     max-width: rem(315);
        // }

        // img {
        //     width: 100%;
        // }
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        transition: $transition ease-in;
    }
}

.t-bannerHome {
    height: 100%;
    display: flex;
    flex-direction: column;

    &_info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &_wrapContent {
        width: 100%;
        margin: rem(24) auto 0;

        @include tablet-down {
            margin: rem(8) auto 0;
        }

        @include mobile-down {
            max-width: 80%;
        }
    }

    &_decor {
        .a-image {
            @include tablet-up {
                min-height: rem(720);
            }

            @include tablet-down {
                @include aspect-ratio(375, 812);
                // padding-bottom: 141.19321%;
            }
        }
    }

    &_content {
        z-index: 2;
        width: 56%;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        height: 100%;
        padding-bottom: rem(68);

        @include desktop-down {
            justify-content: flex-start;
            padding: rem(16) ren(16) rem(48);
        }

        @include tablet-down {
            width: 100%;
        }

        @include mobile-down {
            width: 100%;
            height: fit-content;
            padding: rem(8);
        }

        @include iphone-large-lands {
            min-height: 100vh;
            width: 56%;
            padding-top: 0;
            padding-bottom: 0;
        }

        .a-text-20x24 {
            @include break-min($standard-viewport) {
                font-size: 20 * $w;
                line-height: 24 * $w;
            }
        }
    }

    &_image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc(594 / 1366 * 100%);
        margin-bottom: 0;

        @include desktop-down {
            width: 60%;
            margin-right: auto;
            margin-left: -80px;
        }

        @include tablet-down {
            width: 100%;
            height: 100%;
            margin-left: unset;
        }

        @include iphone-large-lands {
            width: 50%;
            position: absolute;
            z-index: 1;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            max-width: 100%;
            object-position: bottom;
        }
    }

    &_logo {
        width: 100%;
        margin-top: calc(55 / 700 * 100%);
        @include adjust-flex(center, center);

        @include max-desktop-down {
            margin-top: calc(35 / 700 * 100%);
        }

        @include medium-desktop-down {
            margin-top: calc(20 / 700 * 100%);
        }

        @include mobile-down {
            margin-top: rem(20);
            padding: 0 rem(16);
        }

        @include small-mobile {
            margin-top: rem(12);
        }

        &_item {
            width: auto;
            margin-left: rem(24);
            margin-right: rem(24);

            @include max-desktop-down {
                margin-left: rem(18);
                margin-right: rem(18);
            }

            @include desktop-down {
                margin-left: rem(16);
                margin-right: rem(16);
            }

            @include mobile-down {
                margin-left: rem(8);
                margin-right: rem(8);
            }

            img {
                height: rem(75);

                @include max-desktop-down {
                    height: rem(60);
                }

                @include medium-desktop-down {
                    height: rem(40);
                }

                @include iphone-large-lands {
                    height: rem(30);
                }
            }

            &-abbott {
                img {
                    height: rem(55);

                    @include max-large-desktop-down {
                        height: rem(45);
                    }

                    @include max-desktop-down {
                        height: rem(40);
                    }
    
                    @include medium-desktop-down {
                        height: rem(30);
                    }
    
                    @include iphone-large-lands {
                        height: rem(24);
                    }
                }
            }
        }
    }

    &_countdown {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: rem(6);

        &_title {
            .a-text-24x36 {
                @include mobile-down {
                    @include font-base(14, 21);
                }
            }
        }

        @include desktop {
            margin-top: rem(14);
        }

        .m-countdown {
            margin-top: rem(16);

            @include break-min($standard-viewport) {
                margin-top: 16 * $w;
            }

            @include mobile-down {
                margin-top: rem(10);
            }
        }
    }

    &_datetime {
        position: relative;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        // padding: rem(20) rem(20) rem(20) rem(32);
        // background-color: $white-038;
        border-radius: rem(12);
        // background: url('~assets/images/new2025/time_livestream.svg') no-repeat;
        // background: $linear-bg-mb-2025-tranparent;
        background-size: contain;
        background-position: center;
        margin-top: calc(20 / 700 * 100%);

        @include max-desktop-down {
            margin-top: calc(8 / 700 * 100%);
            // padding: rem(16) rem(16) rem(16) rem(24);
        }

        // @include tablet-down {
        //     padding: rem(12) rem(12) rem(12) rem(20);
        // }

        @include mobile-down {
            width: fit-content;
            border-width: 1px;
            border-radius: rem(6);
        }

        @include desktop-down {
            white-space: nowrap;
        }

        &-item {
            border: 2px solid $st-patricks-blue;
            padding: rem(12) rem(16);
            border-radius: rem(12);

            // &-open {
            //     border-bottom: 0;
            //     padding-bottom: 0;
            //     border-bottom-left-radius: 0;
            //     border-bottom-right-radius: 0;
            //     margin-bottom: rem(30);
            // }

            // &-close {
            //     border-top: 0;
            //     padding-top: 0;
            //     border-top-left-radius: 0;
            //     border-top-right-radius: 0;
            //     margin-top: rem(30);
            // }
        }

        // &-and {
        //     position: absolute;
        //     width: fit-content;
        //     height: fit-content;
        //     top: 53%;
        //     left: rem(-15);
        //     transform: translateY(-50%);
        //     z-index: 1;
        //     padding: rem(2) rem(6);
        //     @include font-base(32, 42);
        //     font-weight: 700;
        //     color: $st-patricks-blue;
        // }

        p {
            position: relative;
            z-index: 1;
        }

        &_line {
            width: rem(60);
            height: rem(4);
            margin: rem(8) auto;
            background: linear-gradient(90deg, rgba(175, 175, 175, 0) 0%, rgba(224, 224, 224, 0.7) 12.5%, #afafaf 49.48%, rgba(224, 224, 224, 0.7) 86.46%, rgba(175, 175, 175, 0) 100%);

            @include mobile-down {
                width: rem(48);
                height: rem(2);
                margin-top: rem(4);
                margin-bottom: rem(4);
            }

            @media (max-width: 374px) {
                display: none;
            }
        }

        &_replaytime {
            margin-top: rem(16);
            @include max-desktop-down {
                margin-top: rem(8);
            }
        }

        &_main {
            position: relative;
            margin-bottom: rem(16);
            padding: rem(8) rem(24);
            display: inline-block;

            @include max-desktop-down {
                margin-bottom: rem(12);
            }

            @include iphone-large-lands {
                padding: rem(2) rem(12);
            }

            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                background: $linear-border-date-2025;
                border-radius: rem(4);
                z-index: 0;
            }
    
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #fcf1d1 0%, #fcf5de 50%, #fcf1d1 100%);
                border-radius: rem(4);
                z-index: 0;
            }
        }

        &-button {
            margin-top: rem(10);

            @include iphone-large-lands {
                margin-top: rem(4);
            }
        }

        @media (max-width: 374px) {
            flex-direction: column;
        }
    }

    &_button {
        margin: rem(16) auto 0;
        max-width: rem(243);

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }

        @include break-min($standard-viewport) {
            max-width: 243 * $w;
            margin-top: 16*$w;

            .a-button {
                font-size: 24 * $w;
                height: 56 * $w;
                line-height: 32 * $w;
            }
        }

        @include mobile-down {
            margin-top: rem(8);
            width: rem(190);

            .a-button_wrapper {
                line-height: rem(30);
                margin-top: -2px;
                font-size: rem(20);
            }
        }
    }

    &_title {
        margin-bottom: rem(32);

        @include mobile-down {
            margin-top: rem(16);
        }

        @media (max-width: 376px) {
            .a-text {
                @include font-base(14, 20);
            }
        }
    }

    &_sologan {
        margin-top: rem(-12);

        &.section {
            margin-top: rem(6);
        }

        @include mobile-down {
            margin-top: rem(0);
            padding: 0 rem(8);

            .a-text {
                @include font-base(16, 24);
            }
        }

        @media (max-width: 376px) {
            padding: 0 rem(16);

            .a-text {
                @include font-base(14, 20);
            }
        }
    }

    &_steps {
        display: flex;
        width: 100%;
        flex-grow: 1;
        padding-left: 7%;
        padding-right: 7%;
        margin-top: rem(4);
        align-items: center;
        justify-content: space-between;
        background: url('~assets/images/new/home/banner-decorator.png') no-repeat;
        background-size: cover;

        @include mobile-down {
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            background: none;
        }

        &-notFit {
            flex-grow: 0;
        }
    }

    &_step {
        width: 24%;

        @include mobile-down {
            width: 61.87%;

            &:not(:first-child) {
                margin-top: -15%;
            }

            &:nth-child(2) {
                align-self: flex-end;
            }
        }

        @include small-mobile {
            width: 56%;
        }
    }

    &_title2 {
        margin-top: calc(30 / 700 * 100%);
        margin-bottom: calc(24 / 700 * 100%);
        text-align: center;

        @include max-desktop-down {
            margin-top: calc(25 / 700 * 100%);
        }

        .decor-line {
            margin-top: rem(8);
            width: 25%;
            margin-left: auto;
            margin-right: auto;

            @include mobile-down {
                width: 50%;
            }
        }
    }

    &_noteLeft {
        position: absolute;
        bottom: calc(8 / 700 * 100%);
        left: rem(20);
        z-index: 2;

        @include mobile-down {
            left: rem(8);
        }
    }

    &_note {
        position: absolute;
        bottom: calc(8 / 700 * 100%);
        right: calc(36 / 1366 * 100%);

        @include tablet-down {
            text-align: right;
        }

        @include small-mobile {
            position: relative;
            right: unset;
            bottom: unset;
            margin-top: rem(12);
            display: none;

            p {
                font-size: rem(10);
            }
        }
    }
}
