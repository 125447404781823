.p-eventWaiting {
    margin-top: rem(24);
    @include tablet-down {
        margin-top: rem(16);
    }
  
    &_button {
        width: 100%;
        max-width: rem(270);
        min-width: rem(270);
        margin: rem(16) auto 0;
  
        @include break-min($standard-viewport) {
            max-width: 270 * $w;
        
            .a-button {
                font-size: 24 * $w;
                height: 56 * $w;
                line-height: 32 * $w;
            }
        }
  
        @include mobile-down {
            min-width: inherit;
            width: 100%;
            max-width: rem(190);
            margin: rem(8) auto 0;
  
            .a-text {
                @include font-base(16,24); }
        }
    }
}
