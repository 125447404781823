// fonts
$font-path: '~assets/fonts/';
$font-brandon: 'Brandon Text F';
$font-svn-brandon: 'SVN-Brandon Grotesque';

$icon-path: '~assets/icons/';
// transition
$transition: 0.25s;
$transition-medium: 0.3s;
$transition-long: 0.4s;

// z-index
$z-header: 100;
$z-modal: 101;
$z-1: 1;
$z-2: 2;
$z-loading: 999;

// space
$height-header-desktop: 100;
$height-header-desktop-scroll: 76;
$height-header-tablet-down: 65;

$standard-viewport: 1366px;
$w: 1px /$standard-viewport * 100vw;
$h: 1px/800px * 100vh;
