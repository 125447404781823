.t-bannerLogin {
    height: 100%;
    position: relative;

    &_wrapContent {
        width: 100%;
        padding-top: calc(55 / 700 * 100%);

        @include max-desktop-down {
            padding-top: calc(35 / 700 * 100%);
        }

        @include medium-desktop-down {
            padding-top: calc(20 / 700 * 100%);
        }

        @include tablet-down {
            padding: rem(8) auto 0;
        }

        @include mobile-down {
            max-width: 80%;
        }
    }

    &_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        height: 100%;

        @include desktop-down {
            width: 100%;
            justify-content: flex-start;
            padding: rem(16);
        }

        @include mobile-down {
            padding: 0;
        }

        .a-text-20x24 {
            @include break-min($standard-viewport) {
                font-size: 20 * $w;
                line-height: 24 * $w;
            }
        }
    }

    &_image {
        width: 60%;
        margin-left: rem(-16);
        margin-right: auto;
        margin-bottom: rem(8);
        overflow: hidden;

        @include mobile-down {
            width: 90%;
        }

        @include desktop-up {
            position: absolute;
            top: 0;
            width: 44%;
            left: 0;
            margin-bottom: 0;
            bottom: 0;
        }
    }

    &_logo {
        width: 100%;
        @include adjust-flex(center, center);

        @include mobile-down {
            padding: 0 rem(16);
        }

        &_item {
            width: auto;
            margin-left: rem(24);
            margin-right: rem(24);

            @include max-desktop-down {
                margin-left: rem(18);
                margin-right: rem(18);
            }

            @include desktop-down {
                margin-left: rem(16);
                margin-right: rem(16);
            }

            @include mobile-down {
                margin-left: rem(8);
                margin-right: rem(8);
            }

            img {
                height: rem(75);

                @include max-desktop-down {
                    height: rem(60);
                }

                @include medium-desktop-down {
                    height: rem(40);
                }
            }

            &-abbott {
                img {
                    height: rem(55);

                    @include max-large-desktop-down {
                        height: rem(45);
                    }

                    @include max-desktop-down {
                        height: rem(40);
                    }
    
                    @include medium-desktop-down {
                        height: rem(30);
                    }
    
                    @include iphone-large-lands {
                        height: rem(24);
                    }
                }
            }
        }
    }

    &_datetime {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        text-align: center;
        padding: rem(16);
        border: 2px solid $st-patricks-blue;
        border-radius: rem(12);
        gap: rem(12);
        background-color: $white-038;

        @include mobile-down {
            // width: 80%;
            padding: rem(8);
            margin-bottom: rem(24);
            gap: rem(8);
            border-width: 1px;
            border-radius: rem(6);
        }

        p {
            position: relative;
            z-index: 1;
        }

        &-time {
            text-wrap: nowrap;
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     background: linear-gradient(90deg, #00528c -0.15%, #0067ab 16.9%, #247cbc 44.99%, #0c6eb1 60.03%, #0067ab 72.06%, #00528c 100.15%);
        //     top: -2px;
        //     left: -2px;
        //     width: calc(100% + 4px);
        //     height: calc(100% + 4px);
        //     border-radius: rem(12);
        // }

        // &::after {
        //     content: '';
        //     position: absolute;
        //     background: $white;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     border-radius: rem(12);
        // }

        &_line {
            width: rem(60);
            height: rem(4);
            margin: rem(8) auto;
            background: linear-gradient(90deg, rgba(175, 175, 175, 0) 0%, rgba(224, 224, 224, 0.7) 12.5%, $st-patricks-blue 49.48%, rgba($st-patricks-blue, 0.7) 86.46%, rgba(175, 175, 175, 0) 100%);

            @include mobile-down {
                width: rem(48);
                height: rem(2);
                margin-top: rem(4);
                margin-bottom: rem(4);
            }

            @media (max-width: 374px) {
                display: none;
            }
        }

        @media (max-width: 374px) {
            flex-direction: column;
        }

        &-divider-horizontal {
            position: relative;
            z-index: 1;
            width: 100%;
            height: rem(2);
            background-color: $st-patricks-blue;
        }

        &-divider-vertical {
            width: rem(2);
            height: 100%;
            background: linear-gradient(0deg, rgba(#1768ab, 0.2) 0%, rgba(#214383, 0.7) 12.5%, rgba(#214282, 1) 49.48%, rgba(#214282, 1) 60.48%, rgba(#1768ab, 0.7) 86.46%, rgba(#1768ab, 0) 100%);

            // @include mobile-down {
            //     width: rem(48);
            //     height: rem(2);
            //     margin-top: rem(4);
            //     margin-bottom: rem(4);
            // }

            @media (max-width: 374px) {
                display: none;
            }
        }
    }

    &_title {
        margin-bottom: rem(32);

        @include mobile-down {
            margin-top: rem(16);
        }

        @media (max-width: 376px) {
            .a-text {
                @include font-base(14, 20);
            }
        }
    }

    &_title2 {
        margin-top: rem(32);
        text-align: center;
        margin-bottom: rem(24);

        @include mobile-down {
            margin-top: rem(16);
            margin-bottom: rem(16);
        }

        .decor-line {
            margin-top: rem(8);
            width: 25%;
            margin-left: auto;
            margin-right: auto;

            @include mobile-down {
                width: 50%;
            }
        }
    }

    &_content2 {
        margin-top: calc(30 / 700 * 100%);
        margin-bottom: calc(40 / 700 * 100%);

        @include desktop-down {
            margin-top: calc(20 / 768 * 100%);
            margin-bottom: calc(30 / 768 * 100%);
        }
    }
}