.m-datePicker {
    $root: &;
    position: relative;

    &_wrapper {
        @include font-base(18, 28);
        border-radius: rem(5);
        padding: rem(12) rem(16);
        position: relative;
        width: 100%;

        &:focus {
            outline: 0;
        }
    }
  
    &-sm {
        #{$root}_container {
            #{$root}_wrapper {
                padding: rem(4) rem(12);
            }

            input {
                background-position: right rem(12) top rem(6);
                background-size: rem(14);
            }
        }
    }

    &_container {
        position: relative;

        input {
            background-image: url('~assets/icons/ic_calendar.svg');
            background-position: right rem(16) top rem(10);
            background-repeat: no-repeat;
            background-size: rem(20);
            border: 1px solid $gray-x112;
        }
    }

    &_header {
        @include adjust-flex(center, center);
        flex-direction: row-reverse;

        .m-pulldown {
            &:first-child {
                min-width: rem(88);
                margin-right: rem(9);
            }
        }
    }

    &_calendar {
        border: 0 !important;
        padding: rem(24) rem(16);
        box-shadow: 0 3px 10px rgba($black, 0.6);
        // box-shadow: 0 rem(30) rem(84) $eerie-black13008,
        //   0 rem(8) rem(32) $eerie-black13007, 0 rem(3) rem(14) $eerie-black13003,
        //   0 1px rem(13) $eerie-black13013;
        @include tablet-down {
            padding: rem(16);
        }

        .react-datepicker__day-names {
            display: flex;
            margin-bottom: 0;
        }

        .react-datepicker__day--outside-month {
            opacity: 0.4;
        }

        .react-datepicker__month {
            margin: 0;
        }

        .react-datepicker__day-name {
            @include font-base(14, 21);
            align-items: center;
            // color: $eerie-black;
            display: flex;
            flex-direction: column;
            font-weight: 700;
            height: rem(40);
            justify-content: center;
            width: rem(40);

            @include tablet-down {
                height: rem(32);
                width: rem(32);
            }
            @include mobile-down {
                height: rem(24);
                width: rem(24);
            }
        }

        .react-datepicker__week {
            display: flex;
        }

        .react-datepicker__day {
            align-items: center;
            // color: $eerie-black;
            display: flex;
            flex-direction: column;
            // font-family: $font-kuro;
            font-weight: 500;
            height: rem(40);
            justify-content: center;
            width: rem(40);
            @include font-base(14, 21);
            @include tablet-down {
                height: rem(32);
                width: rem(32);
            }
            @include mobile-down {
                height: rem(24);
                width: rem(24);
            }
        }

        .react-datepicker__day--today {
            border-radius: 50%;
            // color: $avocado;
            font-weight: 500;
            // background: $ghost-whitef8;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day:hover,
        .react-datepicker__day--selected {
            background: $dark-slate-blue;
            color: $white;
        }
    }

    &_popper {
        .react-datepicker__header {
            background: $white;
            border-bottom: 0;
            padding: 0;
        }
    }

    &_label {
        color: $pattrick-blue;
        margin-bottom: rem(4);
        text-transform: uppercase;
        @include font-base(18, 28);
    
        @include adjust-flex(flex-start, center);

        @include mobile-down {
            @include font-base(12, 20);
        }

        &-required {
            color: $red;
            margin-left: rem(4);
        }

        &-icon {
            margin-right: rem(4);
        }
    }

    &_iconError {
        margin-left: rem(4);
    }

    &-light {
        #{$root}_wrapper {
            background-color: $white;
            // border: 1px solid $platinum-1;
            color: $raisin-black;
        }
    }

    &-borderRadiusColor {
        #{$root}_wrapper {
            border-color: $british-racing-green;
            border-radius: rem(12);
        }
    }

    .react-datepicker-popper {
        z-index: 3;
    }
}
