.m-pulldown {
    $root: &;

    &-secondary {
        #{$root}__control {
            border: 1px solid $gray-x112 !important;
            border-radius: 5px;
        }
        // #{$root}__placeholder {
        //   font-size: rem(16) !important;
        //   line-height: rem(24) !important;
        // }
        #{$root}_label {
            color: $pattrick-blue;
            text-transform: uppercase;
            @include font-base(18, 28);
            @include mobile-down {
                @include font-base(14, 18);
            }
        }
        #{$root}__single-value {
            color: $jet;
            font-weight: 500;
        }
    }

    &_icon-indicator {
        background: url('~assets/icons/ic_chevron-down.svg') no-repeat -5px
            center/100%;
        background-size: contain;
        height: rem(30);
        position: relative;
        transform: rotate(0);
        transition: all $transition-long ease;
        width: rem(30);
    }

    &__single-value {
        @include tablet-down {
            @include font-base(10, 18);
        }
    }

    &__option {
        margin-top: rem(12) !important;
        text-transform: uppercase;
        line-height: 24px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__control {
        @include tablet-down {
            padding-left: rem(9) !important;
        }

        &--is-focused {
            box-shadow: 0 0 0 1px rgba($dark-slate-blue, 0.5) !important;
        }
    }

    &__value {
        &-container {
            display: block;
            height: 100%;
            text-transform: uppercase;
        }
    }

    &__placeholder {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: none;
        @include font-base(18, 28);
        // @include desktop-down {
        //   font-size: rem(12) !important;
        // }
        // @include tablet-down {
        //   font-size: rem(10) !important;
        // }
        // @include mobile-down {
        //   font-size: rem(8) !important;
        // }
    }

    &_label {
        color: $dark-slate-blue;
        margin-bottom: 6px;
        @include font-base(16, 21);
    }

    &-datepicker {
        #{$root}__control {
            height: rem(38) !important;
        }
        #{$root}_icon-indicator {
            height: rem(20) !important;
            width: rem(20) !important;
            background-position-x: 0;
        }
        #{$root}__menu-list {
            @include scroll-bars;
        }
    }

    &_required {
        margin-left: rem(4);
        color: $light-brilliant-red;
        font-size: inherit;
    }
}
