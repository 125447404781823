.t-deviceoriented {
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 103;

    &_wrapper {
        background: $white;
        position: relative;
        width: 100%;
        height: 100%;
        @include adjust-flex();
        flex-direction: column;
        margin: auto;

        &::before,
        &::after {
            content: '';
            display: block;
            pointer-events: none;
            position: absolute;
            z-index: $z-1;
        }

        // &::before {
        //     background: transparent url('~assets/images/pop-texture11.png') no-repeat;
        //     background-size: cover;
        //     height: rem(264);
        //     top: -3%;
        //     left: -1%;
        //     transform: rotate(345deg);
        //     width: rem(324);
        // }

        // &::after {
        //     background: transparent url('~assets/images/pop-texture22.png') no-repeat;
        //     background-size: cover;
        //     bottom: 0;
        //     right: 0;
        //     height: rem(200);
        //     width: rem(267);
        // }
    }

    &-logo {
        left: rem(20);
        max-height: rem(50);
        max-width: rem(100);
        position: absolute;
        top: rem(20);
    }

    &_content {
        .a-text-18x28 {
            @include mobile-down {
                @include font-base(18, 28);
            }
        }
    }

    &_image {
        width: 100%;
        max-width: rem(105);
        margin-bottom: rem(32);

        .a-image {
            padding-bottom: 97%;
        }
    }
}
