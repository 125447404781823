.m-floatButtonRegister {
    position: fixed;
    width: fit-content;
    height: fit-content;
    bottom: calc(2 / 700 * 100%);
    right: calc(10 / 1366 * 100%);
    z-index: 50;
    width: rem(190);
    height: fit-content;
    cursor: pointer;
    transition: bottom 0.25s ease-in-out;

    @include max-desktop-down {
        width: rem(140);
    }

    @include desktop-down {
        width: rem(100);
    }

    @include mobile-down {
        bottom: calc(3 / 667 * 100%);
        width: rem(90);
    }

    @include iphone-large-lands {
        bottom: calc(3 / 430 * 100%);
        right: rem(8);
    }

    &_hand {
        animation: heartBeat 2s infinite;
    }

    &_tia {
        animation-duration: 2s;
        animation-timing-function: cubic-bezier(1, 0, 0, 1);
        animation-delay: 2s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: none;
        animation-play-state: running;
        animation-name: fadeIn;
        animation-timeline: auto;
        animation-range-start: normal;
    }

    &-marginBottom {
        bottom: rem(100);
    }
}
