.t-agenda {
    position: relative;

    &_list {
        background: linear-gradient($white, $white) padding-box, $linear-main-border-yellow-2025 border-box;
        border: 2px solid transparent;
        border-radius: rem(16);
        padding: rem(2.16) rem(2.16) rem(20) rem(2.16);
        position: relative;

        &_wrap {
            display: flex;
            border-radius: rem(32);
            padding: rem(70) rem(16) rem(24);
            gap: rem(20);

            @include medium-desktop-down {
                flex-direction: column;
            }

            @include mobile-down {
                padding: rem(40) rem(8) rem(26);
            }
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        //     width: 48.5%;
        //     height: 41.82%;
        //     background: url('~assets/images/testimonials/decorate.png') no-repeat;
        //     background-size: contain;
        //     background-position: right bottom;
        // }
    }

    &_listItem {
        @include tablet-down {
            max-width: 100%;
            padding-left: rem(30);
        }

        @include mobile-down {
            padding-left: 0;
        }
    }

    &_left {
        position: relative;
        flex: 1;
        padding-left: calc(20 / 600 * 100%);
        padding-right: calc(10 / 600 * 100%);

        @include max-large-desktop-down {
            padding-left: calc(12 / 600 * 100%);
            padding-right: calc(2 / 600 * 100%);
        }

        @include medium-desktop-down {
            padding-right: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 10%;
            right: rem(-10);
            height: 80%;
            width: rem(3);
            background: linear-gradient(0deg, rgba(236, 188, 121, 0.2) 0%, rgba(255, 243, 191, 0.8) 8%, rgba(#ecbc79, 0.8) 40%, rgba(#ecbc79, 0.8) 60%, rgba(255, 243, 191, 0.8) 92%, rgba(236, 188, 121, 0.2) 100%);

            @include desktop-down {
                width: rem(2);
            }

            @include medium-desktop-down {
                width: 0;
            }
        }
    }

    &_right {
        flex: 1;
        padding-left: calc(20 / 600 * 100%);
        padding-right: calc(10 / 600 * 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include max-large-desktop-down {
            padding-left: calc(10 / 600 * 100%);
            padding-right: 0;
        }

        @include medium-desktop-down {
            padding-right: 0;
            margin-top: rem(28);
        }
    }

    &_item {
        display: flex;

        &:not(:first-child) {
            margin-top: rem(36);

            @include mobile-down {
                margin-top: rem(28);
            }
        }

        &_datetime {
            white-space: nowrap;
            flex: 0 0 rem(286);
            max-width: rem(286);

            @include max-desktop-down {
                flex: 0 0 rem(220);
                max-width: rem(220);
            }

            @include desktop-down {
                flex: 0 0 rem(180);
                max-width: rem(180);
            }

            @include tablet-down {
                flex: 0 0 rem(160);
                max-width: rem(160);
            }

            @include mobile-down {
                flex: 0 0 rem(130);
                max-width: rem(130);
            }
        }

        &_content {
            width: fit-content;
        }

        &_title {
            position: relative;
            width: fit-content;

            &::before {
                background: linear-gradient(90deg, rgba(203, 152, 49, 0) 0%, rgba(242, 217, 141, 0.8753) 23.47%, #f8e29a 26.81%, #e0ae31 59.71%, #eed88c 79.76%, #f3e8af 87.07%, rgba(224, 174, 49, 0) 99.98%);
                bottom: 0;
                content: '';
                height: 2px;
                left: 40%;
                position: absolute;
                width: 60%;
                transform: translateX(-50%);
            }

            &-title {
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }

        &_subTitle {
            .a-text {
                @include text-background($california-gold);

                @include small-mobile {
                    @include font-base(13, 24);
                }
            }

            &-bckh1 {
                max-width: rem(342);
            }

            &-bckh2 {
                max-width: rem(395);
                min-width: rem(346);

                @include mobile-down {
                    min-width: 0;
                    max-width: rem(250);
                }
            }

            &-bckh3 {
                max-width: rem(415);
                min-width: rem(370);

                @include mobile-down {
                    min-width: 0;
                    max-width: rem(278);
                }
            }
        }

        &_speaker {
            margin-top: rem(16);

            .a-text {
                @include mobile-down {
                    @include font-base(16, 21);
                }
            }
        }
    }


    &_heading {
        position: absolute;
        height: rem(60);
        top: rem(-30);
        left: 50%;
        padding: rem(8) rem(32) rem(0) rem(32);
        border-radius: rem(30);
        transform: translateX(-50%);
        display: flex;
        align-items: center;

        @include tablet-down {
            height: rem(40);
            top: rem(-20);
            padding-left: rem(16);
            padding-right: rem(16);
            padding-top: rem(4);
        }

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            background: $linear-decor-2025;
            border-radius: rem(30);
            z-index: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $linear-main-2025;
            border-radius: rem(30);
            z-index: 0;
        }

        .a-heading {
            position: relative;
            z-index: 1;
            white-space: nowrap;
        }
    }

    &_note {
        max-width: 98%;
        margin-left: auto;
        margin-right: auto;
        color: $dark-cerulean;
        text-align: center;

        @include font-base(24, 32);

        @include medium-desktop-down {
            @include font-base(18, 28);
        }

        @include mobile-down {
            max-width: 98%;
            @include font-base(16, 24);
        }

        @include small-mobile {
            @include font-base(14, 21);
        }

        @include iphone-large-lands {
            @include font-base(12, 18);
        }

        @include iphone-medium-lands {
            @include font-base(10, 14);
        }

        &-title {
            text-wrap: nowrap;
            font-weight: bold;
        }

        &-description {
            text-align: center;
        }

        // &-description {
        //     @include font-base(16, 21);

        //     @include mobile-down {
        //         @include font-base(14, 20);
        //     }

        //     @include small-mobile {
        //         @include font-base(12, 18);
        //     }
        // }
    }
}