.o-soundControl {
    &.muted {
        animation: zooming 1s infinite;
    }
}
@keyframes zooming {
    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1.1);
    }
}
