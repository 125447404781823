.p-thanks {
    position: relative;

    &_bg {
        .a-image {
            @include tablet-down {
                padding-bottom: 216.26667%;
            }
        }
    }

    &_heading {
        position: absolute;
        left: 6%;
        top: 22%;
        width: rem(492);
        z-index: $z-1;

        @include break-min($standard-viewport) {
            width: 492 * $w;
        }

        @include desktop {
            left: 10%;
            top: 18%;
            width: rem(364);
        }

        @include tablet-down {
            left: 50%;
            top: 52%;
            transform: translateX(-50%);
            width: rem(684);
        }

        @include small-mobile {
            top: 50%;
            width: rem(303);
        }

        img {
            width: 100%;
        }
    }

    &_time {
        margin-top: rem(80);
        @include mobile-lands {
            margin-top: rem(40);
        }
    }

    @include mobile-up {
        .t-bannerHome_content {
            width: 60%;
        }
    }
    @include mobile-lands {
        .t-bannerHome_logo {
            margin-bottom: rem(24);
            margin-top: rem(24);
        }

        .t-bannerHome_content {
            width: 60%;
        }

        .t-banner_background .a-image {
            padding-bottom: 54%;
        }
    }

    .t-bannerHome_logo {
        &_item {
            @include mobile-lands {
                width: rem(80) !important;
            }
        }
        
    }

    .t-bannerHome_image {
        width: calc(100% + 32px);
        margin-left: rem(-16);
        margin-right: auto;
        margin-bottom: rem(8);

        @include mobile-up {
            position: absolute;
            top: 0;
            width: 50%;
            left: 0;
            margin-bottom: 0;
        }

        @include mobile-lands {
            position: absolute;
            top: 0;
            width: 50%;
            left: 0;
            margin-bottom: 0;
        }
    }
}
