.p-interactiveOffline {
    &_interactive {
        padding: rem(16) rem(24) rem(48) rem(24);
        background: url('~assets/images/livestream/bg_interactive_new.jpg') no-repeat;
        background-size: cover;
        background-position: center bottom;
        font-family: $font-brandon;
        overflow-y: auto;

        @include mobile-down {
            padding: rem(8) rem(8) rem(80) rem(8);
            background-position: center bottom;
            background-image: url('~assets/images/livestream/bg_interactive_mobile_new.jpg');
        }
    }

    &_logo {
        width: 100%;
        margin-bottom: rem(48);
        @include adjust-flex(center, center);
        @include mobile-down {
            margin-bottom: rem(24);
            padding: 0 rem(16);
        }

        &_item {
            width: rem(114);
            margin-left: rem(12);
            margin-right: rem(12);

            @include break-min($standard-viewport) {
                width: 114 * $w;
            }

            @include mobile-down {
                width: 100%;
                max-width: rem(60);
            }

            &:last-child {
                width: rem(106);
                @include break-min($standard-viewport) {
                    width: 106 * $w;
                }
            }
        }
    }

    &_content {
        max-width: rem(480);
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        padding: rem(16) rem(16) rem(48) rem(16);

        @include tablet-down {
            width: 100%;
            justify-content: flex-start;
            padding: rem(8);
        }

        .a-text-20x24 {
            @include break-min($standard-viewport) {
                font-size: 20 * $w;
                line-height: 24 * $w;
            }
        }
    }

    &_circleImage {
        width: rem(40);
        height: rem(40);

        &_wrapper {
            @include mobile-down {
                width: rem(40) !important;
                margin-left: rem(22);
                margin-right: rem(22);
            }
        }
    }

    &_logo {
        width: 100%;
        @include adjust-flex(center, center);
        @include mobile-down {
            padding: 0 rem(16);
        }

        &_item {
            width: rem(114);
            margin-left: rem(22);
            margin-right: rem(22);


            @include break-min($standard-viewport) {
                width: 114 * $w;
            }

            @include mobile-down {
                width: 100%;
                max-width: rem(74);
                margin-left: rem(12);
                margin-right: rem(12);
            }

            &:last-child {
                width: rem(136);
                @include break-min($standard-viewport) {
                    width: 106 * $w;
                }
            }
        }
    }

    &_title {
        margin-bottom: rem(16);

        .a-text {
            margin-bottom: rem(16);
            @include font-base(16, 20); }

        @include mobile-down {
            margin-top: rem(16);
        }
        @media (max-width: 376px) {
            .a-text {
                @include font-base(14, 20); }
        }
    }

    &_sologan {
        .a-text {
            @include font-base(16, 24); 
            white-space: nowrap;
        }

        &.section {
            margin-top: rem(6);
        }

        @include mobile-down {
            margin-top: rem(0);

            .a-text {
                font-size: rem(18);
                height: rem(46);
                line-height: rem(32);
                margin-top: rem(-22); }
        }

        /* @media (max-width: 376px) {
            .a-text {
                @include font-base(14, 20); }
        } */
    }

    // &_bottom {
    //     width: calc(100% + 32px);
    //     margin-left: -16px;
    //     margin-top: rem(100);
    // }

    &_input {
        border: transparent;
        height: rem(40);
        width: 100%;
        position: relative;
        margin-bottom: rem(24);

        input {
            border-radius: rem(999);
            outline: none;
            -webkit-appearance: none;
            position: relative;
            z-index: 1;
            border: transparent;
            height: rem(40);
            width: 100%;
            padding: 0 rem(20);
            font-size: rem(16);
        }

        &::after {
            content: '';
            border-radius: rem(999);
            position: absolute;
            top: -2px;
            left: -2px;
            background: linear-gradient(90deg, #cb8e2b 0%, #d19634 5%, #e0ac4c 12%, #f9cf74 22%, #ffd87e 24%, #ffd77d 44%, #ffd87e 65%, #fbd379 73%, #efc56c 83%, #dcad55 93%, #ca9740 100%);
            height: rem(44);
            width: calc(100% + rem(4));
            z-index: 0;
        }
    }

    &_divider {
        position: relative;
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        height: rem(26);
        margin-top: rem(-20);
        margin-bottom: rem(20);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &_bottom {
        &_button {
            margin-top: rem(40);

            .a-button {
                background: none;
                aspect-ratio: 559/123;
                width: rem(260);
                height: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        &_title {
            position: relative;
            margin-bottom: rem(24);

            .a-text {
                @include font-base(16, 24);
            }

            &_text {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &_desc {
            .a-text {
                @include font-base(16, 24);
            }
        }
    }

    &_waiting {
        background: url('~assets/images/new/home/banner.jpg') no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center bottom;
        padding: rem(16) rem(16) rem(48) rem(16);
        z-index: 110;
        font-family: $font-brandon;
        overflow-y: auto;

        &_title {
            position: relative;
            aspect-ratio: 518/271;
            padding: rem(32) rem(16);
            padding-top: rem(40);
            background-image: url('~assets/images/gradient_border.png');
            background-position: center;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;

            &_text {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &_divider {
            margin-top: rem(-20);
            margin-bottom: 0;
        }

        &_decor {
            width: calc(100% + 56px);
            margin-left: -32px;
            padding-bottom: rem(32);

            img {
                width: 100%;
            }
        }

        @include mobile-down {
            background: url('~assets/images/new/home/banner-mb.jpg') no-repeat;
            background-size: cover;
            background-position: center bottom;
        }
    }

    &_form {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: rem(260);
        margin: rem(24) auto 0 auto;

        .a-text {
            margin-bottom: rem(12);
            @include font-base(16, 24);
        }
    }

    .t-livestream {
        &_interactive {
            @include mobile-down {
                padding: rem(16) rem(20) rem(8);
                background-image: url('~assets/images/livestream/bg_interactive_mobile_new.jpg');
            }
        }
    }
}
