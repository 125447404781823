.t-loginEvent {
    overflow-y: auto;
    background: $linear-bg-2025;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // @include mobile-down {
    //     background: $linear-bg-mb-2025;
    // }

    &_wrap {
        display: flex;
        gap: rem(20);
        height: 100%;
        padding-top: calc(24 / 650 * 100%);

        @include mobile-down {
            flex-direction: column;
        }

        @include ipad-large-portrait {
            flex-direction: column;
        }
    }

    &_left {
        width: 55%;

        @include desktop-down {
            width: 50%;
        }

        @include mobile-down {
            width: 100%;
        }

        @include mobile-down {
            background: none;
            padding-top: 0;
        }

        @include ipad-large-portrait {
            width: 100%;
        }
    }

    &_right {
        width: 45%;
        padding-top: calc(10 / 700 * 100%);

        @include desktop-down {
            width: 50%;
        }

        @include mobile-down {
            width: 100%;
            padding-top: rem(16);
            padding-bottom: rem(16);
        }

        @include mobile-down {
            padding-top: 0;
        }

        @include ipad-large-portrait {
            width: 100%;
        }
    }

    &_video {
        height: 100vh;
        width: 100%;

        @include desktop-up {
            min-height: rem(680);
        }

        video {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &_button {
        bottom: 10%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: rem(200);

        @include break-min($standard-viewport) {
            width: 220 * $w;

            .a-button {
                font-size: 20 * $w;
                height: 55 * $w;
                line-height: 24 * $w;
            }
        }
    }

    &_input {
        &:not(:first-child) {
            margin-top: rem(16);

            @include tablet-down {
                margin-top: rem(12);
            }
        }
    }

    &_submit,
    &_register {
        margin: rem(24) auto 0;

        @include max-large-desktop-down {
            margin-top: rem(8);
        }

        @include tablet-down {
            margin-top: rem(16);
        }
    }

    &_wrapRegister {
        @include adjust-flex();
    }

    &_forgotPassword {
        width: fit-content;
        margin-top: rem(4);
        margin-bottom: calc(20 / 500 * 100%);
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;

        @include max-large-desktop-down {
            margin-bottom: rem(8);
        }

        @include tablet-down {
            font-size: rem(14);
        }

        .a-text {
            @include font-base(19, 30);
    
            @include max-desktop-down {
                @include font-base(16, 24);
            }
    
            @include tablet-down {
                @include font-base(14, 21);
            }
    
            @include mobile-down {
                @include font-base(18, 24);
            }
    
            @include small-mobile {
                @include font-base(16, 21);
            }
        }
    }

    &_form {
        $form: &;
        overflow: hidden;
        position: relative;
        background-image: $linear-bg-gray-2025, linear-gradient(90deg, #214184 -0.32%, #1c5194 2.7%, #1372b5 7.73%, #0c8acc 13.77%, #0798da 19.81%, #069ddf 23.83%, #18a1d8 24.84%, #54afc0 25.85%, #88baac 27.86%, #b3c49b 29.87%, #d4cb8e 31.88%, #ecd185 33.9%, #fad47f 34.9%, #ffd57d 36.92%, #ffd57d 50%, #f9cd74 51.01%, #dea94c 56.04%, #ce9334 61.07%, #c88b2b 64.09%, #c27a34 67.11%, #b14d4d 73.15%, #9b116e 81.2%, #7f0e5a 100.32%);
        border-radius: rem(24);
        background-origin: border-box;
        background-clip: content-box, border-box;
        border: double rem(3) transparent;

        &_wrapcontent {
            position: relative;
            z-index: $z-2;
            padding: rem(40) rem(40) rem(32);

            @include max-large-desktop-down {
                padding: rem(32) rem(32) rem(20);
            }

            @include max-desktop-down {
                padding: rem(24) rem(24) rem(16);
            }

            @include small-mobile {
                padding: rem(30) rem(16);
            }
        }

        &_headingWrapper {
            display: flex;
            justify-content: center;
        }

        &_heading {
            position: relative;
            margin-bottom: rem(24);
            padding-left: rem(24);
            padding-right: rem(24);

            &::before {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                width: calc(100% + 6px);
                height: calc(100% + 6px);
                background: $linear-decor-2025;
                border-radius: rem(30);
                z-index: 0;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $linear-main-2025;
                border-radius: rem(30);
                z-index: 0;
            }

            @include mobile-down {
                margin-bottom: rem(16);
            }

            #{$form}_title {
                position: relative;
                z-index: 1;
                padding-top: rem(2);
            }
        }

        &-note {
            margin-bottom: rem(12);
        }
    }

    &_divider {
        width: 100%;
        height: rem(1);
        background-color: $blue-pigment;
    }

    &_note {
        margin-top: calc(10 / 700 * 100%);
        margin-bottom: rem(10);
        width: fit-content;
        margin-left: auto;
        padding-right: calc(14 / 1366 * 100%);

        @include mobile-down {
            display: none;
        }
    }
}

.t-login {
    position: relative;

    &_background {
        .a-image {
            @include tablet-down {
                @include aspect-ratio(383, 851);
            }
        }
    }

    &_text {
        left: 8%;
        max-width: rem(528);
        position: absolute;
        top: 20%;
        width: 100%;

        @include break-min($standard-viewport) {
            max-width: 528 * $w;
        }

        @include desktop-down {
            max-width: rem(384);
        }

        @include tablet-down {
            left: 50%;
            max-width: rem(350);
            top: 50%;
            transform: translateX(-50%);
        }

        img {
            width: 100%;
        }
    }

    &_context {
        margin-top: rem(48);

        &_button {
            margin: auto;
            max-width: rem(270);

            @include break-min($standard-viewport) {
                max-width: 270 * $w;

                .a-button {
                    font-size: 24 * $w;
                    height: 56 * $w;
                    line-height: 32 * $w;
                }
            }

            @include mobile-down {
                max-width: rem(190);

            }

            .a-button {
                border-radius: 12px;
                border: 2px solid var(--dkngaystroke, #ecbc79);
                background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

                &_wrapper {
                    background: none;
                }
            }
        }

        &_register {
            text-align: center;
            margin-top: rem(16);

            div {
                margin-left: rem(7);
                cursor: pointer;
            }

            p {
                display: inline-flex;
            }

            @include mobile-down {
                margin-top: rem(8);

                p {
                    @include font-base(14, 22);
                }
            }
        }

        @include mobile-down {
            margin-top: rem(16);
        }
    }

}

.t-resetPassword {
    &_button {
        margin: rem(24) auto;
        max-width: rem(270);

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }
    }
}