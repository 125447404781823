.t-certificate {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    min-height: rem(786);
    position: relative;

    &_form {
        margin: 0 auto;
        max-width: rem(439);

        &_input {
            &:not(:first-child) {
                margin-top: rem(16);
            }

            .a-input_label .a-text {
                text-transform: uppercase;
            }

            .a-input_inputele {
                padding: 0 rem(16);
                text-transform: uppercase;
                font-family: Arial, Helvetica, sans-serif;
            }

            .m-pulldown__single-value {
                font-size: rem(18) !important;
                line-height: rem(28) !important;
            }

            .m-pulldown__control {
                padding-left: rem(15) !important;
            }
        }

        &_submit {
            margin: rem(16) auto 0;
            max-width: rem(294);

            .m-2 {
                margin-left: 0;

                i {
                    width: rem(20);
                    height: rem(20);
                }
            }
        }
    }

    &_field {
        color: $dark-midnight-blue;
        font-size: rem(8.5);
        font-weight: 700;
        left: 36.5%;
        position: absolute;
        text-transform: uppercase;

        &-name {
            top: 32.5%;
        }

        &-birthday {
            top: 37%;
        }

        &-birthplace {
            top: 41.3%;
        }

        &-address {
            top: 45.8%;
        }

        &-notify {
            margin: rem(8) auto 0;
            max-width: rem(467);
        }

        &_button {
            @include adjust-flex();
            margin-top: rem(24);
            @include mobile-down {
                margin-top: rem(16);
            }
        }

        &-confirm {
            max-width: rem(290);
            margin-left: rem(12);
            width: 100%;
            @include mobile-down {
                max-width: rem(220);
                margin-left: rem(6);
            }
        }

        &-back {
            max-width: rem(170);
            width: 100%;
            @include mobile-down {
                max-width: rem(120);
            }
        }
    }

    &_award {
        margin: auto;
        max-width: rem(701);
        position: relative;

        img {
            width: 100%;
        }
    }

    .t-backgroundPanel_wrap {
        background: none;
    }
}

// .o-popup {
//   $root: &;

//   &-popupBackground {
//     max-width: rem(580);
//     #{$root}_heading {
//       margin-bottom: rem(24) !important;
//     }
//   }
//   @include desktop-up {
//     max-height: rem(750);
//   }
// }
