.p-select {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;

    background: $linear-bg-2025;

    &_wrapper {
        flex-grow: 1;
    }

    &_title {
        margin-top: rem(32);
        text-align: center;

        .decor-line {
            margin-top: rem(8);
            width: 25%;
            margin-left: auto;
            margin-right: auto;

            @include mobile-down {
                width: 50%;
            }
        }
    }

    &_content {
        margin-top: calc(28 / 1024 * 100%);
        margin-bottom: rem(60);

        @include max-desktop-down {
            margin-bottom: rem(32);
        }
    }

    &_buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: calc(20 / 1024 * 100%);

        @include tablet-down {
            flex-direction: column;
        }
    }

    &_button {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: rem(50);
        margin-right: rem(50);
        width: 32%;
        padding: rem(12);
        border-radius: rem(16);
        cursor: pointer;

        @include max-desktop-down {
            width: 36%;
        }

        @include desktop-down {
            width: 40%;
            margin-left: rem(24);
            margin-right: rem(24);
        }

        @include tablet-down {
            width: 60%;
            margin-top: rem(32);
        }

        @include mobile-down {
            width: 80%;
            margin-top: rem(48);

            &:first-child {
                margin-top: rem(24);
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            background: $linear-decor-2025;
            border-radius: rem(16);
            z-index: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $linear-gold-white-2025;
            border-radius: rem(16);
            z-index: 0;
        }

        p {
            position: relative;
            z-index: 1;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &_footer {
        position: relative;
        margin-top: rem(24);

        .a-image {
            background-size: contain;
            background-position: bottom;
        }

        &_code {
            position: absolute;
            bottom: rem(8);
            right: rem(12);

            @include small-mobile {
                bottom: rem(1);
                right: rem(8);
            }

            .a-text {
                @include mobile-down {
                    @include font-base(10, 14);
                }
            }
        }
    }
}