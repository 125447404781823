.a-radio {
    $root: &;
    display: inline-block;
    position: relative;

    &_input {
        opacity: 0;
        position: absolute;

        &:checked + #{$root}_body {
            .a-radio_inner {
                background-color: $pattrick-blue;
                border-radius: 100%;
            }
        }
    }

    &_label {
        cursor: pointer;
        display: flex;
        margin: 0;
    }

    &_content {
        font-family: 'SVN-Brandon Grotesque';
        color: $dark-slate-blue;
        font-size: rem(14);
        font-weight: 700;
        margin-left: rem(12);
        letter-spacing: 0.0015em;
         
        @include font-base(32,40);
        @include desktop-down {
            @include font-base(26,40);
        }
        @include tablet-down {
            @include font-base(16,26);
        }
    }

    &_body {
        align-items: center;
        background-color: $white;
        border: 1px solid $pattrick-blue;
        border-radius: 100%;
        display: flex;
        flex: 0 0 auto;
        height: rem(22);
        justify-content: center;
        margin-top: rem(10);
        position: relative;
        width: rem(22);

        @include tablet-down {
            margin-top: rem(4);
            height: rem(18);
            width: rem(18);
        }

        #{$root}_label:hover & {
            opacity: 0.7;
        }

        #{$root}-disabled & {
            opacity: 0.7;
        }
    }

    &_inner {
        height: rem(12);
        width: rem(12);
        @include tablet-down {
            height: rem(10);
            width: rem(10);
        }
    }

    &-disabled {
        pointer-events: none;
    }

    &-square {
        #{$root}_body {
            border-radius: 5px;
            border-width: 2px;
            height: rem(24);
            width: rem(24);
        }
        #{$root}_input:checked + .a-radio_body .a-radio_inner {
            background-color: transparent;
            border-bottom: 2px solid $black;
            border-left: 2px solid $black;
            border-radius: 0;
            height: rem(8);
            margin-top: -4px;
            transform: rotate(-45deg);
            width: rem(14);
        }
    }

    &-vertical {
        #{$root}_body {
            margin-top: 0;
        }
        #{$root}_label {
            align-items: center;
            flex-direction: column;
        }
    }
}
