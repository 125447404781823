.t-finishLive {
    max-width: rem(818);

    &_button {
        @include adjust-flex(center, center);
        max-width: 100%;
        margin-top: rem(24);

        .a-button_wrapper {
            max-width: rem(270);
        }

        @include tablet-down {
            flex-direction: column;
        }

        &_item {
            flex: 0 0 50%;
            max-width: rem(270);
            width: 100%;

            &:first-child {
                margin-right: rem(16);

                @include tablet-down {
                    margin-right: 0;
                    margin-bottom: rem(12);
                }
            }
        }
    }
}
