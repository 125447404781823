.p-home {
    &_teaser {
        padding: rem(60) 0 rem(30);

        &_wrapper {
            position: relative;
            background: linear-gradient(270deg, #e9e9e9 10%, #9f9f9f 24%, #efefef 39%, #a7a7a7 54%, #efefef 70%, #a7a7a7 83%, #ebebeb 96%);
            padding: rem(44) rem(36) rem(36);
            border: 5px solid;
            border-image-source: $linear-main-border-blue-2025;
            border-image-slice: 1;

            @include tablet-down { 
                padding: rem(36) rem(24) rem(24);
            }

            @include mobile-down { 
                padding: rem(32) rem(16) rem(16);
            }
        }

        &_title {
            position: absolute;
            height: rem(60);
            top: rem(-30);
            left: 50%;
            padding: rem(8) rem(32) rem(0) rem(32);
            border-radius: rem(30);
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            @include tablet-down {
                height: rem(40);
                top: rem(-20);
                padding-left: rem(16);
                padding-right: rem(16);
                padding-top: rem(4);
            }

            &::before {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                width: calc(100% + 6px);
                height: calc(100% + 6px);
                background: $linear-decor-2025;
                border-radius: rem(30);
                z-index: 0;
            }
    
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $linear-main-2025;
                border-radius: rem(30);
                z-index: 0;
            }

            .a-heading {
                position: relative;
                z-index: 1;
                white-space: nowrap;
            }
        }

        &_video {
            .t-livestream_sound {
                left: rem(30);
                bottom: rem(30);

                @include desktop-down {
                    left: rem(16);
                    bottom: rem(16);
                }
        
                @include mobile-down {
                    left: rem(12);
                    bottom: rem(12);
                }
            }
        }
    }

    &_agenda {
        padding: rem(60) 0 rem(30);
        position: relative;
        z-index: $z-1;

        @include tablet-down {
            padding: rem(40) 0 rem(20);
  
        }
    }
  
    &_testimonials {
        padding: rem(60) 0 rem(30);
        position: relative;
        z-index: $z-2;
  
        @include tablet-down {
            padding: rem(40) 0 rem(20);
        }
    }
  
    &_instruction {
        padding: rem(30) 0 rem(60);
  
        @include tablet-down {
            padding: rem(20) 0 rem(40);
        }
    }
  
    &_register {
        padding: rem(30) 0;

        @include tablet-down {
            padding: rem(20) 0;

        }
    }
}
