.t-cmesurvey {
    $radiusTitle: 12;
    @include adjust-flex();
    min-height: 100vh;
    position: relative;
    padding: rem(54) rem(40);
    background: $white;

    &::before,
    &::after {
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        z-index: $z-1;
    }

    &::before {
        background: transparent url('~assets/images/pop-texture11.png') no-repeat center/100% auto;
        height: rem(312);
        left: 0;
        top: 0;
        min-width: rem(340);
        width: rem(340);
    }

    &::after {
        background: transparent url('~assets/images/pop-texture22.png') no-repeat center/100% auto;
        height: rem(316);
        right: 0;
        bottom: 0;
        transform: rotate(13deg) translate(19px, 65px);
        min-width: rem(324);
        width: rem(324);
    }


    @include tablet-down {
        padding: rem(27) rem(20);

        &::before {
            width: rem(200);
            height: rem(180);
            min-width: rem(200);
        }
    }

    &_wrapper {
        position: relative;
        z-index: 1;
    }

    &_readyButton {
        max-width: rem(270);
        margin: rem(24) auto 0;

        @include mobile-down {
            margin: rem(16) auto 0;
        }

        .a-button {
            border-radius: rem(12);

            &_wrapper {
                border-radius: rem(12);
            }
        }
    }

    &_ready {
        &_wrap {
            align-items: center;
            background: linear-gradient(90deg, #b2dad8 0%, #e5f5f0 15.47%, #e5f5f0 70.99%, #aad7d4 99.99%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: rem(40);
            padding-top: rem(40);
        }

        &_content {
            margin: rem(24) auto 0;
            max-width: 72%;
            position: relative;
            z-index: $z-2;

            @include desktop-down {
                max-width: rem(821);
            }

            @include mobile-down {
                max-width: rem(612);
            }
        }
    }

    &_start {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        min-height: rem(768);

        @include tablet-down {
            min-height: rem(500);
        }

        &_text {
            margin: rem(24) auto rem(32);
            max-width: 1260px;

            @include tablet-down {
                max-width: rem(650);
            }
        }

        &_button {
            margin: auto;
            max-width: rem(293);
        }
    }

    &_wrapTitle {
        background: $linear-border;
        border-radius: rem($radiusTitle);
        padding: rem(4);
        margin: 0 auto rem(32);

        @include tablet-down {
            margin: 0 auto rem(16);
            border-radius: rem($radiusTitle / 2);
        }
    }

    &_title {
        background: $linear-seal-brown5a;
        border-radius: rem($radiusTitle);
        margin: 0 auto;
        padding: rem(34) rem(16) rem(21);

        @include tablet-down {
            padding: rem(22) rem(21) rem(16);
            border-radius: rem($radiusTitle / 2);

            .a-heading {
                @include font-base(18, 28);
            }
        }
    }

    &_question {
        margin-bottom: rem(24);

        ul.answer {
            list-style: none;

            li {
                &:not(:first-child) {
                    margin-top: rem(16);

                    @include tablet-down {
                        margin-top: rem(10);
                    }
                }

                .a-radio_input:checked+.a-radio_body {
                    &+.a-radio_content {
                        font-weight: 700;
                    }
                }

                .a-radio_body {
                    @include tablet-down {
                        width: rem(18);
                        height: rem(18);
                    }
                }

                .a-radio_content {
                    color: $dark-cerulean;
                    -webkit-text-size-adjust: 100%;

                    @include font-base(32, 48);

                    @include desktop-down {
                        @include font-base(26, 46);
                    }

                    @include tablet-down {
                        @include font-base(16, 26);
                    }
                }
            }
        }
    }

    &_finished {
        margin-left: auto;
        margin-right: auto;
        max-width: rem(1232);

        @include desktop-down {
            max-width: rem(960);
        }

        @include tablet-down {
            max-width: rem(640);
        }

        &_button {
            align-items: center;
            display: flex;
            justify-content: space-around;
            margin: rem(60) auto 0;
            max-width: rem(718);

            @include tablet-down {
                margin-top: rem(28);
            }

            .a-button {
                width: rem(310);

                @include tablet-down {
                    width: rem(280);
                }
            }
        }
    }

    &_form {
        margin-top: rem(32);

        @include tablet-down {
            margin-top: rem(20);
        }

        &_error {
            color: $red;
            font-size: rem(20);
            font-weight: 700;
            margin-top: rem(18);
            text-align: center;
        }
    }

    &_success {
        &_btn {
            max-width: rem(353);
            margin: rem(24) auto 0;

            .a-button {
                border-radius: rem(12);
    
                &_wrapper {
                    border-radius: rem(12);
                }
            }
            @include tablet-down {
                max-width: rem(320);
            }
        }
    }
}
